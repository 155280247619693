// esta es la página de redux donde se manejan todas las solicitudes de la lista de clasificación
// esta contiene fucnion de get, post, put y deletel.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { baseUrl } from "../app/baseUrl";
export const fetchRating = createAsyncThunk(
  "rating/fetchRating",
  async (dispatch, getState) => {
    return await axios.get(`${baseUrl}/rating`).then((res) => res.data);
  }
);

export const addRating = createAsyncThunk(
  "rating/addRating",
  async (value, dispatch, getState) => {
    return await axios
      .post(`${baseUrl}/rating`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const deleteRating = createAsyncThunk(
  "rating/deleteRating",
  async (ratingid, dispatch, getState) => {
    return await axios

      .delete(`${baseUrl}/rating/${ratingid}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const updateRating = createAsyncThunk(
  "rating/updateRating",
  async ({ ratingid, value }, dispatch, getState) => {
    return await axios
      .put(`${baseUrl}/rating/${ratingid}`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

const ratingSlice = createSlice({
  name: "rating",
  initialState: {
    ratingList: [],
    isLoading: false,
  },
  extraReducers: {
    [fetchRating.pending]: (state, action) => {
      state.status = "Loadingfetch";
    },
    [fetchRating.fulfilled]: (state, action) => {
      state.status = "success";
      state.ratingList = action.payload;
    },
    [fetchRating.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addRating.pending]: (state, action) => {
      state.status = "LoadingAdd";
    },
    [addRating.fulfilled]: (state, action) => {
      state.status = "success";
      state.ratingList.push(action.payload);
    },
    [addRating.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateRating.pending]: (state, action) => {
      state.status = "LoadingUpdate";
    },
    [updateRating.fulfilled]: (state, action) => {
      state.status = "success";
      state.ratingList = state.ratingList.filter(
        (item) => item._id !== action.payload._id
      );
      state.ratingList.push(action.payload);
    },
    [updateRating.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteRating.pending]: (state, action) => {
      state.status = "LoadingDelete";
    },
    [deleteRating.fulfilled]: (state, action) => {
      state.status = "success";
      state.ratingList = action.payload;
    },
    [deleteRating.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export const showRating = (state) => state.rating;
export default ratingSlice.reducer;
import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import ratingReducer from '../features/ratingSlice';
import appReducer from '../features/appSlice';
import favouriteReducer from '../features/favouriteSlice';
import contectReducer from '../features/contectSlice';

export const store = configureStore({
  reducer: {
    user:userReducer,
    rating:ratingReducer,
    app:appReducer,
    favourite:favouriteReducer,
    contect:contectReducer,

  },
});

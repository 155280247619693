// This is contect page from here you can send contect info to database.
import React  ,{useEffect,useState} from "react";
import "./ContectUs.css";
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { addContect } from "../../features/contectSlice";

import { useAlert } from "react-alert";
function ContectUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const alert=useAlert()
  const dispatch=useDispatch()
  const [name,setName]=useState('')
  const [surName,setSurname]=useState('')
  const [email,setEmail]=useState('')
  const [message,setMessage]=useState('')

  const HandleSubmit=(event)=>{
    event.preventDefault();
    const value={
      name:name,  
      surname:surName,
      email:email,
      message:message
    }
    dispatch(addContect(value))
    .then((res)=>{alert.success("Tu mensaje ha sido enviado");
    setName("")
    setSurname('')
    setEmail('')
    setMessage('')
  })
    .catch((res)=>alert.error("Tu mensaje no ha sido enviado"))

  }
  return (
    <div className="contentus Contrast1">
      <div className="container-fluid Contrast1">
        <div className="row  Contrast1 contentus__row d-flex justify-content-center  justify-content-lg-start">
          <div className="col-lg-6  Contrast1 col-12 pt-4 pt-lg-0 px-4 p-0 px-lg-5 d-flex justify-content-center flex-column contectus__left">
            <h4 className="text-uppercase">CONTÁCTANOS</h4>
            <p className='pt-lg-4 pt-4'>
              Por favor, rellene el siguiente formulario y nos pondremos en
              contacto con usted lo antes posible.<br/><br/> Este formulario también puede
              utilizarse para enviarnos su experiencia de éxito con alguna
              aplicación que le gustaría compartir y que podamos añadir a
              nuestro sitio web. Por favor, indique:
            </p>
            <ol className='order__list'>
              <li>¿Qué plataforma utilizó y qué navegador está utilizando?</li>
              <li>
                ¿Envíenos todos los links con los que accedió y si alguno le dio
                problemas?
              </li>
              <li>
                Describa si ha habido algún error en su funcionamiento. Por
                ejemplo: se queda colgada la aplicación en algún momento o
                aparece un error 404.
              </li>
              <li>
                {" "}
                Añada una breve descripción de su experiencia y cualquier otro
                dato que pueda ser de utilidad para que podamos evaluar dicha
                aplicación e incluirla en el listado.
              </li>
            </ol>
          </div>
          <div className="col-lg-6 col-10 p-0 py-5 py-lg-5 contectus__right d-flex justify-content-center flex-row"
            style={{
              backgroundImage: "url(/Images/Contact_Background.png)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="row d-flex justify-content-center flex-row Contrast1">
              <div className="col-lg-10 col-10 contectus__right__FFF Contrast1">
                <form  onSubmit={HandleSubmit}>
                  <label htmlFor="basic-url" className="form-label">
                    Nombre <span className="important">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                      type="text"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <label htmlFor="basic-url" className="form-label">
                    Apellidos <span className="important">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      value={surName}
                      onChange={(e)=>setSurname(e.target.value)}
                      type="text"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <label htmlFor="basic-url" className="form-label">
                    Email <span className="important">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <label htmlFor="basic-url" className="form-label">
                    Mensaje <span className="important">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <textarea
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                      className="form-control"
                      id="floatingTextarea2"
                      style={{ height:"235px",  resize: 'none' }}
                    ></textarea>
                  </div>
                  <p className='condition'>El Arca necesita la información que usted nos proporciona para poder
                  contactar en caso necesario. Usted puede dar de baja sus datos en 
                  cualquier momento. Para obtener información sobre cómo hacerlo, así 
                  como nuestras prácticas de privacidad y el compromiso de proteger su 
                  privacidad, por favor revise nuestra <Link to='/privacidad'><span className="policy">Política de Privacidad.</span></Link></p>
                 
                  <button className='submit__button' type='submit'>Enviar</button>
                 

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContectUs;

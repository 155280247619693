// esta es la página redux donde se manejan todas las solicitudes de la lista de aplicaciones
// esta contiene todas las funciones de get, post, put y delete.




import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../app/baseUrl";


export const fetchApp = createAsyncThunk(
  "app/fetchApp",
  async (dispatch, getState) => {
    return await axios.get(`${baseUrl}/applist`).then((res) => res.data );
  }
);
export const addApplist = createAsyncThunk(
  "app/addApplist",
  async (value, dispatch, getState) => {
    return await axios
      .post(`${baseUrl}/applist`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);
export const deleteApplist = createAsyncThunk(
  "app/deleteApplist",
  async (id, dispatch, getState) => {
    return await axios
      .delete(`${baseUrl}/applist/${id}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);
export const updateApplist = createAsyncThunk(
  "app/updateApplist",
  async ({ id, value }, dispatch, getState) => {
    return await axios
      .put(`${baseUrl}/applist/${id}`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);
const applistSlice = createSlice({
  name: "app",
  initialState: {
    appList: [],
    isLoading: false,
  },
  extraReducers: {
    [fetchApp.pending]: (state, action) => {
      state.status = "Loadingfetch";
    },
    [fetchApp.fulfilled]: (state, action) => {
      state.status = "success";
       
      state.appList = action.payload;
    },
    [fetchApp.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addApplist.pending]: (state, action) => {
      state.status = "LoadingAdd";
    },
    [addApplist.fulfilled]: (state, action) => {
      state.status = "success";
    
      state.appList.push(action.payload);
    
    },
    [addApplist.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateApplist.pending]: (state, action) => {
      state.status = "LoadingUpdate";
    },
    [updateApplist.fulfilled]: (state, action) => {
      state.status = "success";
      state.appList = state.appList.filter(
        (item) => item._id !== action.payload._id
      );
      state.appList.push(action.payload);
    },
    [updateApplist.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteApplist.pending]: (state, action) => {
      state.status = "LoadingDelete";
    },
    [deleteApplist.fulfilled]: (state, action) => {
      state.status = "success";
      state.appList = state.appList.filter(
        (item) => item._id !== action.payload._id
      );
    },
    [deleteApplist.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export const showList = (state) => state.app;
export default applistSlice.reducer;

import React, { useState,useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useAlert } from "react-alert";
import { AiFillStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { addRating, updateRating,deleteRating } from "../../features/ratingSlice";
import { useSelector } from "react-redux";
import { showRating } from "../../features/ratingSlice";
import { useParams } from "react-router-dom";
import { showList } from "../../features/appSlice";
import "./RateApp.css";
function RateApp() {
  const alert = useAlert();
  const { id } = useParams();
  const appid=id;
  const { appList } = useSelector(showList);
  const [data, setData] = useState(
    appList.filter((item) => item._id === id).map((item) => item)
  );
  const dispatch = useDispatch();
  const { ratingList } = useSelector(showRating);
  const userData = JSON.parse(localStorage.getItem("Data"));
  const userAuth = JSON.parse(localStorage.getItem("auth"));
  const [rating, setRating] = useState(userAuth && ratingList.filter(item=>item.user!==null).filter(item=>item.user._id===userData._id && item.app===id).map((item,i)=>item.star).reduce((a, b) => a + b, 0));
  const [review, setReview] = useState( userAuth && ratingList.filter(item=>item.user!==null).filter(item=>item.user._id===userData._id && item.app===id).map(item=>item.review).toString());
 
  const HandleSubmit = (event) => {
    event.preventDefault();
    const value = {
      app: id,
      star: rating,
      review: review,
      user: userData._id,
    };
    dispatch(addRating(value))
      .then((res) => {
        setRating(0)
        setReview('')
        alert.success('Su calificación ha sido agregada correctamente')
      })
      .catch((error) => {
        alert.error('Algo salió mal')
       
      });
  };
  const HandleUpdata = (event) => {
    event.preventDefault();
    const ratingid= ratingList.filter(item=>item.user!==null).filter(item=>item.user._id===userData._id && item.app===id).map(item=>item._id)
    const value = {
      app: appid,
      star: rating,
      review: review,
      user: userData._id,
    };
    const data={value,ratingid}
    dispatch(updateRating(data))
    .then((res)=>alert.success('Clasificación Actualizada'))
    .catch((res)=>alert.error('Algo salió mal'))
  };
  const HandleDelete = (event) => {
    event.preventDefault();
    const ratingid= ratingList.filter(item=>item.user._id===userData._id && item.app===id).map(item=>item._id)
    dispatch(deleteRating(ratingid))
    .then((res)=>alert.success('Calificación eliminada'))
    .catch((res)=>alert.error('Algo salió mal'))
  };

  return (
    <div className="rate__app p-lg-5 px-2 py-5 Contrast1">
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div className="row">
              <div className="col-1">
                <button
                  type="button"
                  className="close_button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className="col-11 change_size">
                <h5>Aplicación Seleccionada</h5>
              </div>
            </div>
            <div className="col-12 pt-4 change_size">
              <div className="row">
                <div className="row">
                  <div className="col-2 p-0 ">
                    <img
                      src={data.map((item) => item.icon)}
                      width="60"
                      className="rounded"
                      alt=""
                    />
                  </div>
                  <div className="ps-3 col-10 change_size  d-flex justify-content-center flex-column">
                    <h5>{data.map((item) => item.title)}</h5>
                    <p className="appinfo__grey">
                      Version {data.map((item) => item.version)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 col-lg-8 pt-2 change_size d-flex justify-content-center">
            <div className="change_size">
              <h6 className="app__orange pb-3">Puntuación</h6>
              <div className="change_size">
                {/*  */}
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <AiFillStar
                      onClick={() => setRating(index)}
                      className={`icons_rating ${index <= rating ? "on" : "off"}`}
                      key={index}
                    />
                  );
                })}
              </div>
              <h5 className="pt-3">Opinión</h5>
            </div>
          </div>
          <div className="col-12 change_size">
            <div className="input-group my-3">
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                className="form-control"
                id="floatingTextarea2"
                style={{ height: "235px", resize: "none" }}
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <form onSubmit={HandleUpdata}>
                <button className="px-3 py-1 edit__bor">Editar</button>
                </form>
              </div>
              <div className="col-3 ">
              <form onSubmit={HandleDelete}>
                <button className="px-3 py-1 edit__bor">Borrar</button>
                </form>
              </div>

              <div className="col-3  offset-2 me-2">
                <form onSubmit={HandleSubmit}>
                  <button className="px-3 py-1 tool">Publicar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RateApp;

import React ,{useEffect}from "react";
import "./Privacy.css";
function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="p-lg-5 px-3 py-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Política de Privacidad</h2>
            <hr />
            <p className="heading">
              1. Una visión general de la protección de datos
            </p>
            <p className="sub_heading">Información general</p>
            <p className="content">
              La siguiente información le proporcionará una visión general de lo
              que ocurrirá con sus datos personales cuando visite este sitio
              web. El término "datos personales" comprende todos los datos que
              pueden utilizarse para identificarle personalmente. Para obtener
              información detallada sobre el tema de la protección de datos,
              consulte nuestra Declaración de Protección de Datos, que hemos
              incluido debajo de esta copia.
            </p>
            <p className="sub_heading">Registro de datos en este sitio web</p>
            <p className="sub_heading">
              ¿Quién es el responsable del registro de datos en este sitio web
              (es decir, el "controlador")?
            </p>
            <p className="content">
              Los datos de este sitio web son procesados por el operador del
              sitio web, cuya información de contacto está disponible en la
              sección "Información sobre la parte responsable (denominada
              "controlador" en el GDPR)" en esta Política de Privacidad.
            </p>
            <p className="sub_heading">¿Cómo registramos sus datos?</p>
            <p className="content">
              Recogemos sus datos como resultado de que usted comparta sus datos
              con nosotros. Puede tratarse, por ejemplo, de la información que
              introduzca en nuestro formulario de contacto.
              <br />
              Otros datos serán registrados por nuestros sistemas informáticos
              automáticamente o después de que usted consienta su registro
              durante su visita al sitio web. Estos datos comprenden
              principalmente información técnica (por ejemplo, el navegador web,
              el sistema operativo o la hora de acceso al sitio). Esta
              información se registra automáticamente cuando usted accede a este
              sitio web.
            </p>
            <p className="sub_heading">¿Con qué fines utilizamos sus datos?</p>
            <p className="content">
              Una parte de la información se genera para garantizar el
              suministro sin errores del sitio web. Otros datos pueden
              utilizarse para analizar sus patrones de uso.
            </p>
            <p className="sub_heading">
              ¿Qué derechos tiene usted en relación con sus datos?
            </p>
            <p className="content">
              Tiene derecho a recibir información sobre el origen, los
              destinatarios y los fines de sus datos personales archivados en
              cualquier momento, sin tener que pagar una cuota por dicha
              divulgación. También tiene derecho a exigir que sus datos sean
              rectificados o erradicados. Si ha dado su consentimiento para el
              tratamiento de datos, tiene la opción de revocarlo en cualquier
              momento, lo que afectará a todo el tratamiento de datos futuro.
              Además, tiene derecho a exigir que se restrinja el tratamiento de
              sus datos en determinadas circunstancias. Además, tiene derecho a
              presentar una reclamación ante el organismo de control competente.
              <br />
              No dude en ponerse en contacto con nosotros en cualquier momento
              si tiene alguna pregunta sobre esta u otra cuestión relacionada
              con la protección de datos.
            </p>
            <h6 className="heading">
              Herramientas de análisis y herramientas proporcionadas por
              terceros
            </h6>
            <p className="content">
              Existe la posibilidad de que sus patrones de navegación sean
              analizados estadísticamente cuando visite este sitio web. Estos
              análisis se realizan principalmente con lo que denominamos
              programas de análisis.
              <br />
              Para obtener información detallada sobre estos programas de
              análisis, consulte nuestra Declaración de Protección de Datos más
              abajo.
            </p>
            <p className="heading">2. Alojamiento</p>
            <p className="sub_heading">Alojamiento Externo</p>
            <p className="content">
              Este sitio web está alojado en un proveedor de servicios externo
              (host). Los datos personales recogidos en este sitio web se
              almacenan en los servidores del anfitrión. Estos pueden incluir,
              pero no se limitan a, direcciones IP, solicitudes de contacto,
              metadatos y comunicaciones, información sobre el contrato,
              información de contacto, nombres, acceso a la página web y otros
              datos generados a través de un sitio web.
              <br />
              El host se utiliza con el fin de cumplir el contrato con nuestros
              clientes potenciales y existentes y en el interés de la prestación
              segura, rápida y eficiente de nuestros servicios en línea por un
              proveedor profesional.
              <br />
              Nuestro anfitrión sólo procesará sus datos en la medida necesaria
              para cumplir con sus obligaciones de desempeño y para seguir
              nuestras instrucciones con respecto a dichos datos.
              <br />
              Utilizamos el siguiente host:
              <br />
              1&1 IONOS Cloud GmbH
              <br />
              Revaler Str. 30
              <br />
              10245 Berlin
              <br />
              Alemania
            </p>
            <p className="sub_heading">Tratamiento de datos</p>
            <p className="content">
              Hemos celebrado un acuerdo de procesamiento de datos con el
              proveedor mencionado. Se trata de un contrato exigido por las
              leyes de privacidad de datos que garantiza que procesan los datos
              personales de los visitantes de nuestro sitio web solo en base a
              nuestras instrucciones y en cumplimiento del GDPR.
            </p>
            <p className="heading">
              3. Información general e información obligatoria
            </p>
            <p className="sub_heading">Protección de Datos</p>
            <p className="content">
              Los operadores de este sitio web y sus páginas se toman muy en
              serio la protección de sus datos personales. Por ello, tratamos
              sus datos personales como información confidencial y en
              cumplimiento de las normas legales de protección de datos y de
              esta Declaración de Protección de Datos.
              <br />
              Cada vez que utilice este sitio web, se recopilarán diversos datos
              personales. Los datos personales son aquellos que pueden
              utilizarse para identificarle personalmente. Esta declaración de
              protección de datos explica qué datos recogemos y para qué los
              utilizamos. También explica cómo y para qué se recoge la
              información.
              <br />
              Le informamos de que la transmisión de datos a través de Internet
              (es decir, a través de comunicaciones por correo electrónico)
              puede ser susceptible de sufrir fallos de seguridad. No es posible
              proteger completamente los datos contra el acceso de terceros. .
            </p>
            <p className="sub_heading">
              Información sobre el responsable (denominado "controlador" en el
              GDPR)
            </p>
            <p className="content">
              El responsable del tratamiento de datos en este sitio web es
              <br />
              Miguel González <br />
              Calle Velázquez, 1, 6ºD <br />
              38007 Santa Cruz de Tenerife
              <br />
              Teléfono: [A petición] <br />
              Correo electrónico: elarcaapps@gmail.com <br />
              El responsable del tratamiento es la persona física o jurídica
              que, por sí sola o conjuntamente con otras, toma decisiones sobre
              los fines y los medios del tratamiento de los datos personales
              (por ejemplo, nombres, direcciones de correo electrónico, etc.).
            </p>
            <p className="sub_heading">Duración del almacenamiento</p>
            <p className="content">
              A menos que se haya especificado un periodo de almacenamiento más
              específico en esta política de privacidad, sus datos personales
              permanecerán con nosotros hasta que el propósito para el que
              fueron recogidos deje de ser aplicable. Si usted hace valer una
              solicitud justificada de eliminación o revoca su consentimiento
              para el tratamiento de datos, sus datos serán eliminados, a menos
              que tengamos otras razones legalmente permitidas para almacenar
              sus datos personales (por ejemplo, períodos de retención de la
              legislación fiscal o comercial); en este último caso, la
              eliminación tendrá lugar después de que estas razones dejen de ser
              aplicables.
            </p>
            <p className="sub_heading">
              Revocación de su consentimiento para el tratamiento de datos
            </p>
            <p className="content">
              Un amplio abanico de operaciones de tratamiento de datos sólo es
              posible con su consentimiento expreso. También puede revocar en
              cualquier momento cualquier consentimiento que ya nos haya dado.
              Ello se entenderá sin perjuicio de la legalidad de cualquier
              recogida de datos que se haya producido antes de su revocación.
            </p>
            <p className="sub_heading">
              Derecho a oponerse a la recogida de datos en casos especiales;
              derecho a oponerse a la publicidad directa (art. 21 del RGPD)
            </p>
            <p className="content">
              EN CASO DE QUE LOS DATOS SE TRATEN SOBRE LA BASE DEL ART. 6(1)(E)
              O (F) DEL RGPD, USTED TIENE DERECHO A OPONERSE EN CUALQUIER
              MOMENTO AL TRATAMIENTO DE SUS DATOS PERSONALES POR MOTIVOS
              DERIVADOS DE SU SITUACIÓN PARTICULAR. ESTO TAMBIÉN SE APLICA A
              CUALQUIER ELABORACIÓN DE PERFILES BASADA EN ESTAS DISPOSICIONES.
              PARA DETERMINAR LA BASE JURÍDICA EN LA QUE SE BASA CUALQUIER
              TRATAMIENTO DE DATOS, CONSULTE ESTA DECLARACIÓN DE PROTECCIÓN DE
              DATOS. SI REGISTRA UNA OBJECIÓN, DEJAREMOS DE PROCESAR SUS DATOS
              PERSONALES AFECTADOS, A MENOS QUE ESTEMOS EN CONDICIONES DE
              PRESENTAR MOTIVOS CONVINCENTES DE PROTECCIÓN PARA EL PROCESAMIENTO
              DE SUS DATOS, QUE SUPEREN SUS INTERESES, DERECHOS Y LIBERTADES, O
              SI LA FINALIDAD DEL PROCESAMIENTO ES LA RECLAMACIÓN, EL EJERCICIO
              O LA DEFENSA DE DERECHOS LEGALES (OBJECIÓN CONFORME AL ART. 21(1)
              GDPR).
              <br />
              SI SUS DATOS PERSONALES SE TRATAN PARA REALIZAR PUBLICIDAD
              DIRECTA, USTED TIENE DERECHO A OPONERSE EN CUALQUIER MOMENTO AL
              TRATAMIENTO DE SUS DATOS PERSONALES AFECTADOS CON FINES
              PUBLICITARIOS. ESTO TAMBIÉN SE APLICA A LA ELABORACIÓN DE PERFILES
              EN LA MEDIDA EN QUE ESTÉ RELACIONADA CON DICHA PUBLICIDAD DIRECTA.
              SI SE OPONE, SUS DATOS PERSONALES DEJARÁN DE UTILIZARSE PARA FINES
              DE PUBLICIDAD DIRECTA (OBJECIÓN DE CONFORMIDAD CON EL ART. 21(2)
              DEL GDPR).
            </p>
            <p className="sub_heading">
              Derecho a presentar una reclamación ante la agencia de control
              competente
            </p>
            <p className="content">
              En caso de infracción del RGPD, los interesados tienen derecho a
              presentar una reclamación ante una agencia de control, en
              particular en el Estado miembro en el que tienen su domicilio
              habitual, su lugar de trabajo o el lugar en el que se ha producido
              la presunta infracción. El derecho a presentar una reclamación
              está en vigor independientemente de cualquier otro procedimiento
              administrativo o judicial disponible como recurso legal.
            </p>
            <p className="sub_heading">
              Derecho a la portabilidad de los datos
            </p>
            <p className="content">
              Tiene derecho a exigir que le entreguemos a usted o a un tercero,
              en un formato de uso común y legible por máquina, los datos que
              tratamos automáticamente sobre la base de su consentimiento o para
              cumplir un contrato. Si usted exige la transferencia directa de
              los datos a otro responsable del tratamiento, sólo se hará si es
              técnicamente factible.
            </p>
            <p className="sub_heading">Cifrado SSL y/o TLS </p>
            <p className="content">
              Por razones de seguridad y para proteger la transmisión de
              contenidos confidenciales, como las consultas que usted nos envía
              como operador del sitio web, este sitio web utiliza un programa de
              encriptación SSL o TLS. Puede reconocer una conexión cifrada
              comprobando si la línea de dirección del navegador cambia de
              "http://" a "https://" y también por la aparición del icono del
              candado en la línea del navegador.
              <br /> Si el cifrado SSL o TLS está activado, los datos que nos
              transmita no podrán ser leídos por terceros.
            </p>
            <p className="sub_heading">
              Información, rectificación y erradicación de datos
            </p>
            <p className="content">
              En el marco de las disposiciones legales aplicables, usted tiene
              derecho a exigir en cualquier momento información sobre sus datos
              personales archivados, su origen y sus destinatarios, así como la
              finalidad del tratamiento de sus datos. También puede tener
              derecho a que se rectifiquen o eliminen sus datos. Si tiene
              preguntas sobre este tema o cualquier otra cuestión sobre los
              datos personales, no dude en ponerse en contacto con nosotros en
              cualquier momento.
            </p>
            <p className="sub_heading">
              Derecho a exigir la limitación del tratamiento
            </p>
            <p className="content">
              Tiene derecho a exigir la imposición de restricciones en el
              tratamiento de sus datos personales. Para ello, puede ponerse en
              contacto con nosotros en cualquier momento. El derecho a exigir la
              restricción del tratamiento se aplica en los siguientes casos:
            </p>
            <ul className="content">
              <li className="content">
                En el caso de que usted cuestione la exactitud de sus datos
                archivados por nosotros, normalmente necesitaremos algún tiempo
                para verificar esta reclamación. Durante el tiempo que dure esta
                investigación, usted tiene derecho a exigir que restrinjamos el
                tratamiento de sus datos personales.
              </li>
              <li className="content">
                Si el tratamiento de sus datos personales se ha llevado a cabo
                de forma ilegal, tiene la opción de exigir la restricción del
                tratamiento de sus datos en lugar de exigir la erradicación de
                los mismos.
              </li>
              <li>
                {" "}
                Si ya no necesitamos sus datos personales y usted los necesita
                para ejercer, defender o reclamar derechos legales, tiene
                derecho a exigir la restricción del tratamiento de sus datos
                personales en lugar de su erradicación.
              </li>
              <li className="content">
                Si ha presentado una objeción de conformidad con el art. 21(1)
                del RGPD, sus derechos y los nuestros tendrán que sopesarse.
                Mientras no se determine de quién son los intereses que
                prevalecen, usted tiene derecho a exigir que se restrinja el
                tratamiento de sus datos personales.
              </li>
            </ul>
            <p className="content">
              Si ha restringido el tratamiento de sus datos personales, estos
              datos -con la excepción de su archivo- solo podrán ser tratados
              con su consentimiento o para reclamar, ejercer o defender derechos
              legales o para proteger los derechos de otras personas físicas o
              jurídicas o por razones importantes de interés público citadas por
              la Unión Europea o un Estado miembro de la UE.
            </p>
            <p className="heading">4. Registro de datos en este sitio web</p>
            <p className="sub_heading">Archivos de registro del servidor</p>
            <p className="content">
              El proveedor de este sitio web y de sus páginas recoge y almacena
              automáticamente información en los llamados archivos de registro
              del servidor, que su navegador nos comunica automáticamente. La
              información comprende:
            </p>
            <ul>
              <li className="content">
                El tipo y la versión del navegador utilizado
              </li>
              <li className="content">El sistema operativo utilizado</li>
              <li className="content">La URL de referencia</li>
              <li className="content">
                El nombre de host del ordenador que accede
              </li>
              <li className="content">La hora de la consulta al servidor</li>
              <li className="content">La dirección IP</li>
            </ul>
            <p className="content">
              Estos datos no se combinan con otras fuentes de datos.
              <br />
              Estos datos se registran sobre la base del Art. 6(1)(f) del GDPR.
              El operador del sitio web tiene un interés legítimo en la
              representación técnicamente libre de errores y la optimización del
              sitio web del operador. Para ello, es necesario registrar los
              archivos de registro del servidor.
            </p>
            <p className="sub_heading">
              Formulario de contacto y registro de usuario
            </p>
            <p className="content">
              Si nos envía sus consultas a través de nuestro formulario de
              contacto, la información proporcionada en el formulario de
              contacto, así como cualquier información de contacto proporcionada
              en el mismo, será almacenada por nosotros con el fin de gestionar
              su consulta y en el caso de que tengamos más preguntas. No
              compartiremos esta información sin su consentimiento.
              <br />
              El tratamiento de estos datos se basa en el Art. 6(1)(b) del GDPR,
              si su solicitud está relacionada con la ejecución de un contrato o
              si es necesario llevar a cabo medidas precontractuales. En todos
              los demás casos, el tratamiento se basa en nuestro interés
              legítimo en la tramitación efectiva de las solicitudes dirigidas a
              nosotros (Art. 6(1)(f) GDPR) o en su acuerdo (Art. 6(1)(a) GDPR)
              si se ha solicitado.
              <br />
              La información que haya introducido en el formulario de contacto
              permanecerá con nosotros hasta que nos pida que eliminemos los
              datos, revoque su consentimiento para el archivo de datos o si el
              propósito para el que se archiva la información ya no existe (por
              ejemplo, después de que hayamos concluido nuestra respuesta a su
              consulta). Ello se entenderá sin perjuicio de las disposiciones
              legales obligatorias, en particular los plazos de conservación.
              <br />
            </p>
            <p className="sub_heading">
              Solicitud por correo electrónico, teléfono o fax
            </p>
            <p className="content">
              Si se pone en contacto con nosotros por correo electrónico,
              teléfono o fax, su solicitud, incluidos todos los datos personales
              resultantes (nombre, solicitud) serán almacenados y procesados por
              nosotros con el fin de procesar su solicitud. No transmitimos
              estos datos sin su consentimiento.
              <br />
              Estos datos se procesan sobre la base del Art. 6(1)(b) del GDPR si
              su solicitud está relacionada con el cumplimiento de un contrato o
              es necesaria para la ejecución de medidas precontractuales. En
              todos los demás casos, los datos se procesan sobre la base de
              nuestro interés legítimo en la gestión eficaz de las consultas que
              se nos presentan (Art. 6(1)(f) GDPR) o sobre la base de su
              consentimiento (Art. 6(1)(a) GDPR) si se ha obtenido.
              <br />
              Los datos que nos envíe a través de las solicitudes de contacto
              permanecerán con nosotros hasta que nos pida que los eliminemos,
              revoque su consentimiento para el almacenamiento o el propósito
              del almacenamiento de datos caduque (por ejemplo, después de la
              finalización de su solicitud). Las disposiciones legales
              obligatorias -en particular los períodos de conservación legales-
              no se ven afectadas.
              <br />
            </p>
            <p className="heading">5. Herramientas de análisis</p>
            <p className="sub_heading">Google Analytics</p>
            <p className="content">
              Google Analytics es un servicio analítico de web prestado por
              Google, Inc., una compañía de Delaware cuya oficina principal está
              en 1600 Amphitheatre Parkway, Mountain View (California), CA
              94043, Estados Unidos (“Google”).
              <br />
              Google Analytics utiliza “cookies”, que son archivos de texto
              ubicados en tu ordenador, para ayudar al website a analizar el uso
              que hacen los usuarios del sitio web.La información que genera la
              cookie acerca de tu uso del website (incluyendo tu dirección IP)
              será directamente transmitida y archivada por Google. Google usará
              esta información por cuenta nuestra con el propósito de seguir la
              pista de su uso del website, recopilando informes de la actividad
              del website y prestando otros servicios relacionados con la
              actividad del website y el uso de Internet.
              <br />
              Google podrá transmitir dicha información a terceros cuando así se
              lo requiera la legislación, o cuando dichos terceros procesen la
              información por cuenta de Google. Google no asociará su dirección
              IP con ningún otro dato del que disponga.
              <br />
              Como usuario, y en ejercicio de tus derechos, puedes rechazar el
              tratamiento de los datos o la información rechazando el uso de
              cookies mediante la selección de la configuración apropiada de tu
              navegador, sin embargo, debes saber que si lo hace puede que no
              puedas usar la plena funcionabilidad de este sitio web.
              <br />
              Al utilizar esta web, de acuerdo a la información facilitada en
              esta Política de Privacidad, aceptas el tratamiento de datos por
              parte de Google en la forma y para los fines indicados.
              <br />
              Para más información, puedes consultar la política de privacidad
              de Google en https://www.google.com/intl/es/policies/privacy/. .{" "}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;

import React, { useState,useEffect } from "react";
import "./App.css";
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home/Home";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import FamEdu from "./Pages/FamiliesandEducators/Fam_Edu";
import FullList from "./Pages/FullListPage/FullList";
import ContectUs from "./Pages/ContectUs/ContectUs";
import UserLogin from "./Pages/UserLogin/UserLogin";
import AppInfo from "./Components/AppInfo/AppInfo";
import AppDescription from "./Components/AppInfo/AppDescription/AppDescription";
import RateApp from "./Components/RateApp/RateApp";
import Privacy from "./Components/Privacy/Privacy";
import Legal from "./Components/Legal/Legal";
import Cookies from "./Components/Cookies/Cookies";
import UserProfile from "./Components/UserProfile/UserProfile";
import { BiReset } from "react-icons/bi";
// importar { Accesibilidad } desde ' accessibility/src/main';
import {useDispatch} from 'react-redux'


import ScrollToTop from "./Components/ScrolTotop/ScrolToTop";
import { fetchRating } from "./features/ratingSlice";
import { fetchApp } from "./features/appSlice";
import { fetchFavourite } from "./features/favouriteSlice";
function App() {
// todos los datos fetch de esta function
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRating());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(fetchApp());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFavourite());
  }, [dispatch]);


  const [textSize, setTextsize] = useState(2.916);
  const [toggle, setToggle] = useState(false);
  const [wordSpacing, setWordspacing] = useState(0);
  const [lineHight, setHinehight] = useState(23);
  const [invertColor, setIvertcolor] = useState(false);
  const [contrast, setContrast] = useState({ bgColor: "", Color: "" });
  const [saturation, setSaturation] = useState(4);
  const [textDecoration, setTextDecoration] = useState(false);
  const ArrayColor = ["#000", "#fff", "#000", "rgb(3, 236, 11)", "", ""];
  const [increment, setIncrement] = useState(0);
  const HandleColor = () => {
    setContrast({
      bgColor: ArrayColor[increment],
      Color: ArrayColor[increment + 1],
    });
    setIncrement(increment + 2);
    if (increment > 3) {
      setIncrement(0);
    }
  };
  const HandleReset = () => {
    setTextsize(2.916);
    setToggle(false);
    setWordspacing(0);
    setHinehight(23);
    setIvertcolor(false);
    setContrast({ bgColor: "", Color: "" });
    setSaturation(4);
    setTextDecoration(false);
    setIncrement(0);
  };
  const RenderOffcanvas = () => {
    return (
      <>
        <div>
          {/* Componentes del botón de accesibilidad */}
          <Button className="mybutton" onClick={() => setToggle(!toggle)}>
            <img src="/Icons/FAB.png" width="60" alt="FAB" />
          </Button>
          <Offcanvas
            direction="end"
            isOpen={toggle}
            toggle={() => setToggle(!toggle)}
            className='Contrast1'
          >
            <OffcanvasHeader toggle={() => setToggle(!toggle)}>
             Accesibilidad
              <BiReset className="BiReset" onClick={() => HandleReset()} />
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    {" "}
                    <button
                      className="buttonAss"
                      onClick={() => setTextsize(textSize + 0.5)}
                    >
                      <img src={'/Icons/Icon_1.png'} width='30' alt="icon 1"/><br/>
                      Aumentar texto
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="buttonAss"
                      onClick={() =>
                        setTextsize(textSize > 2.916 ? (textSize - 0.5) : 2.916)
                      }
                    >
                      {" "}
                       <img src={'/Icons/Icon_2.png'} width='30' alt="icon 1"/><br/>
                      Disminuir texto{" "}
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="buttonAss"
                      onClick={() => setWordspacing(wordSpacing + 10)}
                    >
                      <img src={'/Icons/Icon_3.png'} width='30' alt="icon 1"/><br/>
                      Aumentar el espaciado
                    </button>
                  </div>
                  <div className="col-6">
                    {" "}
                    <button
                      className="buttonAss"
                      onClick={() =>
                        setWordspacing(wordSpacing >= 0 && wordSpacing - 10)
                      }
                    >
                       <img src={'/Icons/Icon_4.png'} width='30' alt="icon 1"/><br/>
                      Disminuir el espaciado
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="buttonAss"
                      onClick={() => setHinehight(lineHight + 10)}
                    >
                       <img src={'/Icons/Icon_5.png'} width='30' alt="icon 1"/><br/>
                      Altura de la línea
                    </button>
                  </div>
                  <div className="col-6">
                    {" "}
                    <button
                      className="buttonAss"
                      onClick={() =>
                        setHinehight(lineHight >= 23 && lineHight - 10)
                      }
                    >
                       <img src={'/Icons/Icon_6.png'} width='30' alt="icon 1"/><br/>
                     Altura de la línea
                    </button>
                  </div>
                  <div className="col-12">
                    <button
                      className="buttonAss"
                      onClick={() => setIvertcolor(!invertColor)}
                    >
                       <img src={'/Icons/Icon_7.png'}  width='40' className='pe-3' alt="icon 1"/>
                       Invertir colores
                    </button>
                  </div>
                  <div className="col-12">
                    <button className="buttonAss" onClick={() => HandleColor()}>
                    <img src={'/Icons/Icon_8.png'}  width='40' className='pe-3' alt="icon 1"/>
                      Alto contraste
                    </button>
                  </div>
                  <div className="col-12">
                    {" "}
                    <button
                      className="buttonAss"
                      onClick={() =>
                        setSaturation(
                          (saturation <= 1 && saturation + 1) ||
                            (saturation >= 4 && saturation - 1)
                        )
                      }
                    >
                       <img src={'/Icons/Icon_9.png'}  width='40' className='pe-3' alt="icon 1"/>
                      Saturación
                    </button>
                  </div>
                  <div className="col-12">
                    <button
                      className="buttonAss"
                      onClick={() => setTextDecoration(!textDecoration)}
                    >
                       <img src={'/Icons/Icon_10.png'} width='40' className='pe-3' alt="icon 1"/>
                      Realzar enlaces
                    </button>
                  </div>
                </div>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </>
    );
  };
  return (
    <>
    {/* Este es el estilo interno principal que se utiliza para cambiar las fuentes y los colores */}
      <style type="text/css">
        {` @media screen and (min-width: 991px){
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5{
    font-size: ${textSize}vw !important;
    font-family:  Euclid-SemiBold;
  }
  
  p{
    max-width: none;
    font-size:${textSize - 1.416}vw !important;;
  }
  .icons{
    font-size:3.5rem;
  }
}

@media screen and (max-width:479px){
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    width: auto;
    font-size: ${(textSize - 0.916)*4}vw;
  }
  
  p{
    max-width: none;
    font-size:  ${(textSize - 0.916)*2}vw;
  }
  .icons{
    font-size:2rem;
  }
}

       .Line_Space{word-spacing:${wordSpacing}px !important; }
       .Line_Hight{line-height:${lineHight}px !important; }
       .InvertColor{filter: invert(100%);}
       .Contrast1{background-color:${contrast.bgColor} !important; color:${
          contrast.Color
        } !important; }
        button{background-color:${contrast.Color} !important; color:${
          contrast.bgColor
        } !important; }
       .saturate_effect1 {filter: saturate(0) }
       .saturate_effect2 {filter: saturate(0.5) }
       .saturate_effec3 {filter: saturate(3) }
       .saturate_effec3 {filter: unset }
       a {text-decoration: ${textDecoration ? "underline" : "unset"}!important}
    `}
      </style>
      <BrowserRouter>
        <div
          className={`position-relative Line_Hight font_Size  ${
            saturation && ` saturate_effect${saturation}`
          }   Line_Space ${invertColor && "InvertColor"} ${"Contrast1"} `}
        >
          <RenderOffcanvas />
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/nosotros" element={<AboutUs />} />
            <Route path="/" element={<Home />} />
            <Route path="/familias" element={<FamEdu />} />
            <Route path="/educadores" element={<FamEdu />} />
            <Route path="/lista-completa" element={<FullList />} />
            <Route path="/contacto" element={<ContectUs />} />
            <Route path="/appinfo/:id" element={<AppInfo />} />
            <Route path="/appdescription" element={<AppDescription />} />
            <Route path="/rateapp/:id" element={<RateApp />} />
            <Route path="/privacidad" element={<Privacy />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-login" element={<UserLogin />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;

/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./AppInfo.css";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { TbHeartPlus } from "react-icons/tb";
import { AiFillStar } from "react-icons/ai";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import AppDescription from "./AppDescription/AppDescription";
import RateApp from "../RateApp/RateApp";
import { showRating } from "../../features/ratingSlice";
import { useParams } from "react-router-dom";
import { showList } from "../../features/appSlice";
import {useDispatch} from 'react-redux'
import { addFavourite, showFavourite } from "../../features/favouriteSlice";
const LongText = ({ content, limit }) => {
  if (content.length <= limit) {
    // no hay nada más que mostrar
    return <div>{content}</div>;
  }
  // En el último caso, mostramos un texto con elipsis y un botón "Leer más"
  const toShow = content.substring(0, limit) + "...";

  return (
    <div>
      {toShow}
      <button
        type="button"
        className="ShowMore"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
      >
        . Leermás
      </button>
    </div>
  );
};
function AppInfo() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const alert = useAlert();
  const dispatch=useDispatch()
  const { id } = useParams();
  const { appList } = useSelector(showList);
  const {favouriteList}=useSelector(showFavourite)
  const [data, setData] = useState(
    appList.filter((item) => item._id === id).map((item) => item)
  );
  const [contentShow, setcontentShow] = useState(false);
  const userDataauth = JSON.parse(localStorage.getItem("auth"));
  const { ratingList } = useSelector(showRating);
  const [fiveStar, setFivestar] = useState(
   0
  );
  const[appRating,setApprating]=useState(0.0)

  const [fourStar, setfourStar] = useState(
    0
  );

  const [threeStar, setthreeStar] = useState(
    0
  );

  const [twoStar, settwoStar] = useState(
    0
  );

  const [oneStar, setoneStar] = useState(
    0
  );
  const [favourite,setFavourite]=useState()

  useEffect(() => {
    setFavourite(userDataauth && favouriteList.filter(item=>item.user !==null).filter(item=>item.user._id===userData._id && item.applist._id===id).length)
    setFivestar(
      (ratingList.filter((item) => item.star === 5 && item.app===id).length /
        ratingList.filter((item) => item.app===id).length) *
        100
    );
    setfourStar(
      (ratingList.filter((item) => item.star === 4 && item.app===id).length /
        ratingList.filter((item) => item.app===id).length) *
        100
    );
    setthreeStar(
      (ratingList.filter((item) => item.star === 3 && item.app===id).length /
        ratingList.filter((item) => item.app===id).length) *
        100
    );
    settwoStar(
      (ratingList.filter((item) => item.star === 2 && item.app===id).length /
        ratingList.filter((item) => item.app===id).length) *
        100
    );
    setoneStar(
      (ratingList.filter((item) => item.star === 1 && item.app===id).length /
        ratingList.filter((item) => item.app===id).length) *
        100
    );
    setApprating(ratingList.filter((item) => item.app === id).length !==0? ratingList.filter((item) => item.app === id).map(item=>item.star).reduce((a, b) => a + b, 0)/ratingList.filter((item) => item.app === id).length:0.0)
  }, [ratingList]);
  const userData = JSON.parse(localStorage.getItem("Data"));
  const HandleFavorite=(event)=>{
    event.preventDefault();
    const value={
      user:userData._id,
      applist:id
      
    }
   
    dispatch(addFavourite(value))
    .then((res)=>alert.success('Agregar a favoritos'))
    .catch((res)=>alert.error('Algo salió mal'))
  }
  return (
    <>
      <div
        className="modal fade Contrast1"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body Contrast1">
              <AppDescription des={data.map((item) => item.description)} title={data.map((item) => item.title)} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body Contrast1 bordr">
            {userDataauth && <RateApp />}
            </div>
          </div>
        </div>
      </div>

      <div className="appinfo p-lg-5  px-2 py-5  Contrast1">
        <div className="container-fluid p-0 Contrast1">
          <div className="row d-flex justify-content-center p-0 Contrast1">
            <div className="col-lg-7 col-md-12 col-12 Contrast1">
              <div className="container-fluid  Contrast1">
                <div className="row Contrast1">
                  <div className="col-lg-12 appinfo__section1 d-flex justify-content-start flex-wrap flex-row overflow Contrast1">
                    <div className="row flex-row flex-nowrap appinfo__section1__scroll Contrast1" style={{height:"50vh"}}>
                      {data.map((item) =>
                        item.screenshot.map((image) => {
                          return (
                            <div className="col-lg-10  col-md-10   col-sm-10  col-10 appinfo__section1__images">
                              <img src={image} width="100%" alt={item.title} />
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__setion2  Contrast1">
                    <div className="container-fluid p-0 pt-5 Contrast1">
                      <div className="row Contrast1">
                        <div className="col-md-12 col-12 Contrast1">
                          <div className="row">
                            <div className="col-md-6 col-12 Contrast1">
                              <div className="row">
                                <div className="col-md-3 col-3 Contrast1">
                                  {data.map((item) => (
                                    <img
                                      src={item.icon}
                                      width="80"
                                      className="rounded"
                                      alt={item.title}
                                    />
                                  ))}
                                </div>
                                <div className="change_size col-8 col-md-8 ps-4 d-flex justify-content-center flex-column">
                                  {data.map((item) => (
                                    <h5>{item.title}</h5>
                                  ))}
                                  {data.map((item) => (
                                    <p className="appinfo__grey">
                                      Version: {item.version}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                              <form onSubmit={HandleFavorite}>
                             <button className='clear' type='submit'> <TbHeartPlus className={`icon ${favourite > 0 && 'FavouriteApp'}`} /></button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__section3 Contrast1">
                    <div className="container-fluid p-0 pt-4 Contrast1">
                      <div className="row change_size Contrast1">
                        <h5>
                          Proveedor:{" "}
                          {data.map((item) => (
                            <span className="appinfo__grey">
                              {item.company}
                            </span>
                          ))}
                        </h5>
                        <h5 className="appinfo__grey">
                          Precio:{data.map((item) => item.price)}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__section4 pt-4 Contrast1">
                    <div className="container-fluid p-0 Contrast1">
                      <div className="row d-flex justify-content-between Contrast1">
                        {data.filter(item=>item.ratingplay!=="").map((item) => (
                          <div className="col-md-6 d-flex justify-content-center flex-column Contrast1">
                            <a href={item.playLink}  className='d-flex justify-content-center flex-column' target="_blank">
                              <button className="appinfo__button w-100 me-auto ms-auto d-flex justify-content-center align-items-center">
                                <AiFillAndroid /> Descargar
                              </button>

                              <label className="appinfo__grey text-center">
                                Descargar desde Google Play
                              </label>
                            </a>
                          </div>
                        ))}
                        {data.filter(item=>item.ratingapple !=="").map((item) => (
                          <div className="col-md-6  Contrast1">
                            <a href={item.appleLink} className='d-flex justify-content-center flex-column' target="_blank">
                              <button className="appinfo__button w-100 me-auto ms-auto d-flex justify-content-center align-items-center">
                                <AiFillApple /> Descargar
                              </button>
                              <label className="appinfo__grey text-center">
                                Descargar desde Apple Store
                              </label>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__section5 pt-4 Contrast1">
                    <div className="container-fluid p-0 Contrast1">
                      <div className="row Contrast1">
                        <div className="col-md-12 change_size Contrast1">
                          <h4 className="pb-2">Opinión de ’BridgingApps’</h4>
                          {data.map((item) => {
                            return (
                              <LongText
                                content={item.description}
                                limit={200}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__section6 pt-3 Contrast1">
                    <div className="container-fluid p-0 Contrast1">
                      <div className="row Contrast1">
                        <div className="col-md-12 py-3 change_size Contrast1">
                          <h1>Valoraciones y Opiniones de ’El Arca’</h1>
                        </div>
                        <div className="col-md-12 Contrast1  appinfo__rating px-5">
                          <div className="row Contrast1">
                            <div className="col-md-4 Contrast1 d-flex  align-items-center flex-column">
                              <h1 className="rating">{appRating }</h1>
                              <h3 className="text-center h3">sobre 5</h3>
                            </div>
                            <div className="col-md-8 col-12 Contrast1">
                              <div className="row d-flex justify-content-center Contrast1">
                                <div className="row Contrast1" >
                                  <div className="col-md-5 col-5 me-auto Contrast1">
                                    <img
                                      src="/Icons/5star.png"
                                      alt="5star"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-md-7 col-7 Contrast1">
                                    <div className="progress mt-2 Contrast1">
                                      <div
                                        className="progress-bar  progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: fiveStar }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5 col-5 me-auto">
                                    <img
                                      src="/Icons/4star.png"
                                      alt="4star"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-md-7 col-7">
                                    <div className="progress mt-2">
                                      <div
                                        className="progress-bar  progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="50"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: fourStar }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row Contrast1">
                                  <div className="col-md-5 col-5 me-auto">
                                    <img
                                      src="/Icons/3star.png"
                                      alt="3star"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-md-7 col-7 Contrast1">
                                    <div className="progress mt-2">
                                      <div
                                        className="progress-bar  progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: threeStar }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5 col-5 me-auto">
                                    <img
                                      src="/Icons/2star.png"
                                      alt="2star"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-md-7 col-7 Contrast1">
                                    <div className="progress mt-2">
                                      <div
                                        className="progress-bar  progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: twoStar }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5 col-5 me-auto">
                                    <img
                                      src="/Icons/1star.png"
                                      alt="1star"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-md-7 col-7 Contrast1">
                                    <div className="progress mt-2">
                                      <div
                                        className="progress-bar  progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: oneStar }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row pt-4 Contrast1">
                              <div className="col-md-6 d-flex justify-content-center justify-content-lg-start Contrast1">
                                {" "}
                                <h6 className="sm__h6">{ratingList.filter((item) => item.app === id).length} Opiniones</h6>{" "}
                              </div>
                              <div className="col-md-6 d-flex justify-content-center justify-content-lg-end Contrast1">
                                <h6
                                  className="todos sm__h6"
                                  onClick={() => setcontentShow(!contentShow)}
                                >
                                  Todas las Opiniones
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-12  Contrast1 appinfo__section7  pt-4 ${
                      contentShow ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="container-fluid p-0 Contrast1">
                      <div className="row Contrast1">
                        <div className="col-lg-12 appinfo__rating pt-4 Contrast1">
                          <div className="row Contrast1">
                            {ratingList.filter((item)=>item.app===id).map((item,i) => (
                              <>
                                <div className="col-md-1" key={i}>
                                  <img
                                    className=""
                                    width={50}
                                    src={item.user !==null&&item.user.profileImg}
                                    alt={item.user !== null ?item.user.username:'N/A'}
                                  />
                                </div>
                                <div className="col-md-11 change_size Contrast1">
                                  <h5>{item.review}</h5>
                                  <p className="appinfo__grey">
                                    {item.user !==null ?item.user.username:'User Removed'} ·{" "}
                                    {new Intl.DateTimeFormat("en-US", {
                                      dateStyle: "short",
                                    }).format(
                                      new Date(Date.parse(item.createdAt))
                                    )}
                                  </p>
                                  <div className="col-md-12">
                                    {[...Array(parseInt(item.star))].map(
                                      (star, index) => {
                                        index += 1;
                                        return (

                                          <AiFillStar className="gold__rating" />
                                        );
                                      }
                                    )}
                                    {/* {item.star.map(item=>{ })} */}
                                  </div>
                                  <hr />
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 appinfo__section8 pt-4 Contrast1">
                    <div className="container-fluid p-0 Contrast1">
                      <div className="row Contrast1">
                        <div className="col-md-12 change_size Contrast1">
                          <h4>Califica y opina sobre esta aplicación</h4>
                          <label className="appinfo__grey">
                            Dile a otros miembros de la comunidad que te ha
                            parecido
                          </label>
                        </div>
                        <div className="col-md-12 pt-3 d-flex justify-content-center">
                          {userDataauth ? (
                            <img
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                              src="/Icons/StarIcon.png"
                              width="30%"
                              alt="gallery"
                            />
                          ) : (
                            <Link
                              className="d-flex justify-content-center"
                              to="/user-login"
                              param={data.map(item=>item._id)}
                            >
                              {" "}
                              <img
                                src={"/Icons/StarIcon.png"}
                                width="30%"
                                alt="gallery"
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppInfo;

 
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { showList } from "../../features/appSlice";
import AppCard from "../../Components/AppCard/AppCard";
import "./Fam_Edu.css";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
function FamEdu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { appList } = useSelector(showList);
  return (
    <div className="fam_edu  Contrast1">
      <section className="fam_edu__section__top  Contrast1">
        <div className="container-fluid  Contrast1">
          <div className="row pt-4  Contrast1 ps-lg-4 py-lg-0">
            <div className="col-lg-4  Contrast1 col-12 py-5 py-lg-0 ps-lg-5 d-flex align-items-center align-items-lg-start justify-content-center text-center text-lg-start flex-column fam__edu__content">
              <Fade bottom>
                <h1> APPs INCLUSIVAS PARA TODAS LAS CAPACIDADES</h1>
                <button className="my-5">
                  <Link to="/lista-completa"> Listado de Aplicaciones</Link>
                </button>
              </Fade>
            </div>
            <div className="col-lg-7  Contrast1 offset-lg-1">
              <div className="row   Contrast1 d-flex justify-content-end">
                <div className="col-lg-6 Contrast1">
                  <div className="row d-flex flex-column Contrast1">
                    <div className="col-lg-12  Contrast1 d-lg-block d-none d-lg-flex justify-content-end align-items-end fam_edu__left__top">
                      <Fade left>
                        <h4>
                          {" "}
                          <a href="#fam_edu__section__mid" className="">
                            FAMILIAS
                          </a>{" "}
                        </h4>
                      </Fade>
                    </div>
                    <div className="col-lg-12 col-12 fam_edu__left__buttom Contrast1">
                      <Fade bottom>
                        <h4>
                          {" "}
                          <a href="#fam_edu__section__edu" className=" p-0 m-0">
                            EDUCADORES
                          </a>{" "}
                        </h4>
                      </Fade>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 fam_edu__right Contrast1">
                  <Fade top>
                    <a href="#fam_edu__section__med" className=" p-0 m-0">
                      {" "}
                      <h4>CUIDADORES</h4>
                    </a>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fam_edu__section__mid Contrast1" id="fam_edu__section__mid">
        <div className="container-fluid Contrast1">
          <div className="row fam_edu__section__mid__padding    Contrast1 d-flex justify-content-end">
            <div className="col-lg-5 offset-lg-1  Contrast1  p-0">
              <div>
                <Fade bottom>
                  <div className="section2__icon  Contrast1 pt-2 d-flex justify-content-center">
                    <img
                      className="d-lg-block d-none"
                      src="/Icons/Para_Familias_Icon.png"
                      width="40"
                      alt="paraFamiliasIcon"
                    />
                  </div>
                  <div className="section2__heading Contrast1  px-3 pt-1">
                    <h1>
                      PARA <br />
                      FAMILIAS
                    </h1>
                  </div>
                  <div className="section2__content  pb-5 mt-3">
                    <p>
                      El uso de dispositivos táctiles ayuda a tus hijos y seres
                      queridos con necesidades especiales o discapacidad. Sin embargo,
                      encontrar APPs adecuadas para ellos puede ser complicado y
                      confuso. Por eso pretendemos que este sitio web sea un
                      atajo para ello. Te proponemos a continuación algunas de
                      las APPs más útiles para familias con necesidades
                      especiales.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>{" "}
            <div className=" offset-lg-1-custom   Contrast1 col-md-12  col-lg-Custom-Size   fix-height d-flex flex-row flex-wrap justify-content-center justify-content-lg-end  ">
              <div className="d-contents">
                  {appList
                    .filter((item) => item.para.includes("familias"))
                    .slice(0, 4)
                    .map((item) => {
                      return (
                       <Fade left>
                        <Link  params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <>
                            <AppCard data={item} />
                          </>
                        </Link>
                       </Fade> 
                      );
                    })}
              </div>
            </div>
            <div className="col-md-11 col-lg-custom-size-div2 offset-send-right  Contrast1   flex-wrap    d-flex flex-row justify-content-lg-start justify-content-center">
                {appList
                  .filter((item) => item.para.includes("familias"))
                  .slice(4)
                  .map((item) => {
                    return (
                      <Fade left>
                      <div>
                        <Link  params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <AppCard data={item} />
                        </Link>
                      </div>
                </Fade>
                    );
                  })}
            </div>
            <div className="row Contrast1">
              <div className="col-lg-10 Contrast1 offset-lg-1 ps-lg-4">
                <div className="row Contrast1">
                  <div className=" px-3 Contrast1 col-lg-10 offset-lg-1 p-lg-4 p-0 section__button">
                    <p>
                    No has encontrado la aplicación que necesitas. Explora
                    nuestro motor de búsqueda personalizado y encuentra
                    aplicaciones clasificadas por categoría o palabras clave para
                    satisfacer sus necesidades específicas.
                    </p>

                    <button className="mt-5">
                      <Link to="/lista-completa"> Listado de Aplicaciones</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fam_edu__section__edu Contrast1" id="fam_edu__section__edu">
        <div className="container-fluid Contrast1">
          <div className="row fam_edu__section__mid__padding  Contrast1 d-flex justify-content-end">
            <div className="col-lg-5 offset-lg-1 Contrast1">
              <Fade bottom>
                <div className="section2__icon pt-2 d-flex justify-content-center">
                  <img
                    className="d-lg-block d-none"
                    src="/Icons/ParaEducadoresIcon.png"
                    width="40"
                    alt="paraFamiliasIcon"
                  />
                </div>
                <div className="section2__heading px-3 pt-1 Contrast1">
                  <h1>
                    PARA
                    <br />
                    EDUCADORES
                  </h1>
                </div>
                <div className="section2__content pb-5 mt-3 Contrast1 ">
                  <p>
                    Muchos educadores cuando preparan el nuevo curso escolar
                    dedican tiempo a buscar APPs para dispositivos móviles que
                    les ayuden en sus clases. Buscan aplicaciones de
                    organización, interactivas que les permitan trabajar
                    materias como las matemáticas, la lectura, los
                    conocimientos, etc. Encontrar aquellas que sean
                    transferibles a necesidades especiales no es fácil. Por eso
                    El Arca ha creado para ellos esta lista resumida de
                    aplicaciones útiles con ese objetivo.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="offset-lg-1-custom  ps-lg-4   Contrast1 col-md-12  col-lg-Custom-Size   fix-height d-flex flex-row flex-wrap justify-content-center justify-content-lg-end ">
              <div className="d-contents">
                  {appList
                    .filter((item) => item.para.includes("educadores"))
                    .slice(0, 4)
                    .map((item) => {
                      return (
                        <Fade left>
                        <Link params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <>
                            <AppCard data={item} />
                          </>
                        </Link>
                 </Fade>
                      );
                    })}
              </div>
            </div>
            <div className=" col-md-11 col-lg-custom-size-div2 offset-send-right  Contrast1   flex-wrap    d-flex flex-row justify-content-lg-start justify-content-center">
                {appList
                  .filter((item) => item.para.includes("educadores"))
                  .slice(4)
                  .map((item) => {
                    return (
                      <Fade left>
                      <div>
                        <Link  params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <AppCard data={item} />
                        </Link>
                      </div>
                        </Fade>
                    );
                  })}
            </div>
          </div>
          <div className="row Contrast1">
            <div className="col-lg-10 Contrast1 offset-lg-1 ps-lg-5">
              <div className="row  Contrast1">
                <div className=" px-3  Contrast1 col-lg-10 offset-lg-1 p-lg-4 p-0 section__button">
                  <p>
                    No has encontrado la aplicación que necesitas. Explora
                    nuestro motor de búsqueda personalizado y encuentra
                    aplicaciones clasificadas por categoría o palabras clave para
                    satisfacer sus necesidades específicas.
                  </p>

                  <button className="mt-5">
                    <Link to="/lista-completa"> Listado de Aplicaciones</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fam_edu__section__med Contrast1" id="fam_edu__section__med">
        <div className="container-fluid Contrast1">
          <div className="row  Contrast1 fam_edu__section__mid__padding d-flex justify-content-end">
            <div className="col-lg-5 Contrast1 offset-lg-1 ">
              <Fade bottom>
                <div className="section2__icon Contrast1 pt-5 d-flex justify-content-center">
                  <img
                    className="d-lg-block d-none"
                    src="/Icons/ParacuidadoresIcon.png"
                    width="40"
                    alt="paraFamiliasIcon"
                  />
                </div>
                <div className="section2__heading  Contrast1 px-3 pt-1">
                  <h1>
                    PARA
                    <br />
                    CUIDADORES
                  </h1>
                </div>
                <div className="section2__content  pb-5 mt-3 ">
                  <p>
                    Si eres abuelo/a, pareja, vecino, personal de
                    apoyo de sanitario o amigo/a, bienvenido porque seguramente
                    eres parte de este “sándwich”. Todos están cuidando a
                    alguien. Muchos han descubierto que usar dispositivos
                    táctiles ayuda de muchas maneras a sus seres queridos con
                    necesidades especiales. Sin embargo, encontrar aplicaciones
                    apropiadas para sus necesidades puede ser desafiante, difícil
                    y eterno. Por ello, vemos nuestro sitio web como una
                    simplificación en ese proceso.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="offset-lg-1-custom  ps-lg-4   Contrast1 col-md-12  col-lg-Custom-Size   fix-height d-flex flex-row flex-wrap justify-content-center justify-content-lg-end ">
              <div className="d-contents">
                  {appList
                    .filter((item) => item.para.includes("cuidadores"))
                    .slice(0, 4)
                    .map((item) => {
                      return (
                        <Fade left>
                        <Link  params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <>
                            <AppCard data={item} />
                          </>
                        </Link>
                   </Fade>
                      );
                    })}
              </div>
            </div>
            <div className="  col-md-11 col-lg-custom-size-div2 offset-send-right  Contrast1   flex-wrap    d-flex flex-row justify-content-lg-start justify-content-center">
                {appList
                  .filter((item) => item.para.includes("cuidadores"))
                  .slice(4)
                  .map((item) => {
                    return (
                      <Fade left>
                      <div>
                        <Link params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}>
                          <AppCard data={item} />
                        </Link>
                      </div>
              </Fade>
                    );
                  })}
            </div>
          </div>

          <div className="row Contrast1">
            <div className="col-lg-10 Contrast1 offset-lg-1 ps-lg-5">
              <div className="row Contrast1">
                <div className=" Contrast1 px-3 col-lg-10 offset-lg-1 p-lg-4 p-0 section__button">
                  <p>
                  No has encontrado la aplicación que necesitas. Explora
                    nuestro motor de búsqueda personalizado y encuentra
                    aplicaciones clasificadas por categoría o palabras clave para
                    satisfacer sus necesidades específicas.
                  </p>

                  <button className="mt-5">
                    <Link to="/lista-completa"> Listado de Aplicaciones</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FamEdu;

// esta es la página redux donde se manejan todas las solicitudes de conectar
// esta contiene las funciones de get, post, put y delete.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../app/baseUrl";
export const fetchContect = createAsyncThunk(
  "contect/fetchContect",
  async (dispatch, getState) => {
    return await axios.get(`${baseUrl}/contect`).then((res) => res.data);
  }
);

export const addContect = createAsyncThunk(
  "contect/addContect",
  async (value, dispatch, getState) => {
    return await axios
      .post(`${baseUrl}/contect`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const deleteContect = createAsyncThunk(
  "contect/deleteContect",
  async (ratingid, dispatch, getState) => {
    return await axios
      .delete(`${baseUrl}/contect/${ratingid}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const updateContect = createAsyncThunk(
  "contect/updateContect",
  async ({ ratingid, value }, dispatch, getState) => {
    return await axios
      .put(`${baseUrl}/contect/${ratingid}`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

const contectSlice = createSlice({
  name: "contect",
  initialState: {
    contectList: [],
    isLoading: false,
  },
  extraReducers: {
    [fetchContect.pending]: (state, action) => {
      state.status = "Loadingfetch";
    },
    [fetchContect.fulfilled]: (state, action) => {
      state.status = "success";
      state.contectList = action.payload;
    },
    [fetchContect.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addContect.pending]: (state, action) => {
      state.status = "LoadingAdd";
    },
    [addContect.fulfilled]: (state, action) => {
      state.status = "success";
      state.contectList.push(action.payload);
    },
    [addContect.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateContect.pending]: (state, action) => {
      state.status = "LoadingUpdate";
    },
    [updateContect.fulfilled]: (state, action) => {
      state.status = "success";
      state.contectList = state.contectList.filter(
        (item) => item._id !== action.payload._id
      );
      state.contectList.push(action.payload);
    },
    [updateContect.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteContect.pending]: (state, action) => {
      state.status = "LoadingDelete";
    },
    [deleteContect.fulfilled]: (state, action) => {
      state.status = "success";
      state.contectList = action.payload;
    },
    [deleteContect.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const showcontect = (state) => state.contect;
export default contectSlice.reducer;
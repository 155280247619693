import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/js/bootstrap.min";
import AlertTemplate from 'react-alert-template-basic'
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
const container = document.getElementById('root');
const root = createRoot(container);
const options = {
  // tambien se puede utilizar el 'botón central'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  // también se puede utilizar al 'escala'
  transition: transitions.SCALE
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>
);

// Recordar: para medir el rendimiento en la aplicación, pasar a función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o enviar a un endpoint de análisis. Recordar que hay más información en: https://bit.ly/CRA-vitals
reportWebVitals();

// esta es la página redux donde se manejan todas las solicitudes de la lista de favoritos
// esta contiene las fucniones de get, post, put y delete.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { baseUrl } from "../app/baseUrl";


export const fetchFavourite = createAsyncThunk(
  "favourite/fetchFavourite",
  async (dispatch, getState) => {
    return await axios.get(`${baseUrl}/favourite`).then((res) => res.data);
  }
);

export const addFavourite = createAsyncThunk(
  "favourite/addFavourite",
  async (value, dispatch, getState) => {
    return await axios
      .post(`${baseUrl}/favourite`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const deleteFavourite = createAsyncThunk(
  "favourite/deleteFavourite",
  async (favid, dispatch, getState) => {
    return await axios
      .delete(`${baseUrl}/favourite/${favid}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

export const updateFavourite = createAsyncThunk(
  "favourite/updateFavourite",
  async ({ ratingid, value }, dispatch, getState) => {
    return await axios
      .put(`${baseUrl}/favourite/${ratingid}`, value, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => res.data);
  }
);

const ratingSlice = createSlice({
  name: "favourite",
  initialState: {
    favouriteList: [],
    isLoading: false,
  },
  extraReducers: {
    [fetchFavourite.pending]: (state, action) => {
      state.status = "Loadingfetch";
    },
    [fetchFavourite.fulfilled]: (state, action) => {
      state.status = "success";
      state.favouriteList = action.payload;
    },
    [fetchFavourite.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addFavourite.pending]: (state, action) => {
      state.status = "LoadingAdd";
    },
    [addFavourite.fulfilled]: (state, action) => {
      state.status = "success";
      state.favouriteList.push(action.payload);
    },
    [addFavourite.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateFavourite.pending]: (state, action) => {
      state.status = "LoadingUpdate";
    },
    [updateFavourite.fulfilled]: (state, action) => {
      state.status = "success";
      state.favouriteList = state.favouriteList.filter(
        (item) => item._id !== action.payload._id
      );
      state.favouriteList.push(action.payload);
    },
    [updateFavourite.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteFavourite.pending]: (state, action) => {
      state.status = "LoadingDelete";
    },
    [deleteFavourite.fulfilled]: (state, action) => {
      state.status = "success";
      state.favouriteList = state.favouriteList.filter(
        (item) => item._id !== action.payload._id
      );
    },
    [deleteFavourite.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export const showFavourite = (state) => state.favourite;
export default ratingSlice.reducer;
import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import { AiFillCamera } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { adduser, deleteUser } from "../../features/userSlice";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../app/baseUrl";
function UserProfile() {
  const nevigation = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("Data"));
  const userDataauth = JSON.parse(localStorage.getItem("auth"));
  const [picture, setPicture] = useState();
  const [imgData, setImgData] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [dateOfbirth, setDateOfbirth] = useState('');
  const [gender, setGender] = useState('');
  const [relationship, setRelationship] = useState('');
  const [country, setCountry] = useState('');
  useEffect(() => {
    setName(userDataauth && userData.username);
    setEmail(userDataauth && userData.email);
    setImgData(
      userDataauth && userData.profileImg
    );
    setDateOfbirth(userDataauth && userData.dateOfbirth);
    setGender(userDataauth && userData.gender);
    setRelationship(userDataauth && userData.relationship);
    setCountry(userDataauth && userData.country);
  }, [userDataauth]);

  let formData = new FormData();
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const HandleSubmit = (event) => {
    event.preventDefault();

    formData.append("username", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("dateOfbirth", dateOfbirth);
    formData.append("gender", gender);
    formData.append("relationship", relationship);
    formData.append("country", country);
    formData.append("profileImg", picture);
    dispatch(adduser(formData))
      .then((res) => {
        alert.success("Tu registro se ha completado con éxito");
        setName("");
        setEmail("");
        setImgData("");
        setDateOfbirth("");
        setGender("");
        setRelationship("");
        setCountry("");
      })
      .catch((error) => {
        alert.error("Error " + error);
      });
  };
  const HandleDelete = (event) => {
    event.preventDefault();
    if (userDataauth) {
      dispatch(deleteUser(userData._id)).then((response) => {
        nevigation("/");
        alert.success("Usuario eliminado");
      });
    }
  };
  return (
    <div className="user__profile Contrast1">
      <div className="container-fluid Contrast1">
        <div className="row">
          <div className="col-12  py-2">
            <form onSubmit={HandleSubmit}>
              <div className="form-group row py-2 ">
                <label
                  htmlFor="nickName"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                ></label>
                <div className="col-sm-7">
                  <div className="main">
                    <img src={imgData} alt="Upload" className="img-responsive" />
                    <label className="center">
                      <AiFillCamera className="" />
                      <input
                        type="file"
                        name="myImage"
                        onChange={onChangePicture}
                        className="input-large"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row py-2 ">
                <label
                  htmlFor="nickName"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Apodo / Alias
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    id="nickName"
                  />
                </div>
              </div>
              <div className="form-group row py-2 ">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-7">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="staticEmail"
                  />
                </div>
              </div>
             {userDataauth ?"": <div className="form-group row py-2">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Contraseña
                </label>
                <div className="col-sm-7">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    placeholder="Password"
                  />
                </div>
              </div>}
              <div className="form-group row py-2">
                <label
                  htmlFor="staticEmail1"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Fecha de nacimiento
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    value={dateOfbirth}
                    onChange={(e) => setDateOfbirth(e.target.value)}
                    className="form-control"
                    id="staticEmail1"
                  />
                </div>
              </div>
              <div className="form-group row py-2">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Fecha de nacimiento
                </label>
                <div className="col-sm-7">
                  <select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option value="Mujer">Mujer</option>
                    <option value="Hombre">Hombre</option>
                    <option value="No_binario"> No binario</option>
                    <option value="Prefiero_no_decirlo">
                      'Prefiero no decirlo
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group row py-2">
                <label
                  htmlFor="staticEmail2"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  País
                </label>
                <div className="col-sm-7">
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="form-select form-control"
                    id="country"
                    name="country"
                  >
                    <option>select country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, Democratic Republic of the Congo
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curacao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="XK">Kosovo</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">
                      Macedonia, the Former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="CS">Serbia and Montenegro</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.s.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div className="form-group row py-2">
                <label
                  htmlFor="staticEmail3"
                  className="col-sm-4 text-lg-end text-md-end text-start col-form-label"
                >
                  Relación
                </label>
                <div className="col-sm-7">
                  <select
                    value={relationship}
                    onChange={(e) => setRelationship(e.target.value)}
                    className="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option value="Persona_con_discapacidad_o_necesidades_especiales">
                      Persona con discapacidad o necesidades especiales
                    </option>
                    <option value="Familiar_de_persona_con_discapacidad_o_necesidades_especiales">
                      Familiar de persona con discapacidad o necesidades
                      especiales
                    </option>
                    <option value="Educador_de_persona_con_discapacidad_o_necesidades_especiales">
                      {" "}
                      Educador de persona con discapacidad o necesidades
                      especiales
                    </option>
                    <option value="Terapeuta_de_persona_con_discapacidad_o_necesidades_especiales">
                      Terapeuta de persona con discapacidad o necesidades
                      especiales
                    </option>
                    <option value="Cuidador_de persona_con_discapacidad_o_necesidades_especiales">
                      Cuidador de persona con discapacidad o necesidades
                      especiales
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group row py-2">
                <div className="offset-4 col-sm-6">
                  <button className="user__profile__button mb-0">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
            <div className="form-group row py-2">
              <div className="col-sm-11 d-flex justify-content-end">
                <form onSubmit={HandleDelete}>
                  <button className="user__profile__button">
                    Eliminar Cuenta
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;

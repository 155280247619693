import React, { useEffect, useState } from "react";
import "./FullList.css";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import AppCard from "../../Components/AppCard/AppCard";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { showList } from "../../features/appSlice";
function FullList() {
  // esta function la usamos para hacer scroll page arriba.
  useEffect(() => {
    window.scrollTo(0, 0)
    },[])
  const [appName, setAppname] = useState("");
  const [catagory, setCatagory] = useState("");
  const [order, setOrder] = useState("");
  const [filterAB, setFilter] = useState("");
  const { appList } = useSelector(showList);
  const [filterData, setFilterData] = useState(appList.map((item) => item));
  const HandleReset = () => {
    setCatagory("");
    setOrder("");
    setFilter("");
  };
  // este use effect ayuda a implementar Filtros. contiene todas las condiciones de los filtros
  
  useEffect(() => {
    const orderList = appList.map((item) => item);
    const filterList = () => {
      // esto se usa sólo para el campo manual de entrada (búsqueda por palabras)
      if (appName !== "" && order === "" && catagory === "" && filterAB === "") {
        return appList
          .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
          .map((item) => item);
      }
    // esta condición es solo para Categorías
      else if (order === "" && catagory !== "" && filterAB === "" && appName === "") {
        return appList
          .filter((item) => (item.filter1.includes(catagory)))
          .map((item) => item);
      } 
      else if (order === "" && catagory !== "" && filterAB === "" && appName !== "" ) {
        return appList
          .filter((item) => (item.filter1.includes(catagory)))
          .filter((item) =>
          item.description.toLowerCase().includes(appName.toLowerCase())
        )
          .map((item) => item);
      } 
         // Esta condición es solo para Ordenar por
      else if (order !== "" && catagory === "" && filterAB === "" && appName === "") {
        if (order === "De menor a mayor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .map((item) => item);
        } else if (order === "De mayor a menor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
            .map((item) => item);
        }
      } 
            // esta condición es sólo para Ordenar por y el campo manual de entrada (búsqueda por palabras)
      else if (order !== "" && catagory === "" && filterAB === "" && appName !== "") {
        if (order === "De menor a mayor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .map((item) => item);
        } else if (order === "De mayor a menor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .map((item) => item);
        }
      } 
        // esta condición es solo para Filtrar por 
      else if (order === "" && catagory === "" && filterAB !== "" && appName === "") {
        if (
          filterAB === "ipad" ||
          filterAB === "iphone" ||
          filterAB === "web application" ||
          filterAB === "android"
        )
          return appList
            .filter((item) => item.devices.includes(filterAB))
            .map((item) => item);
        else if (filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3") {
          return appList
          .filter((item, i) =>  item.ageRange.includes(filterAB))
            .map((item) => item);
        } 
      } 
      else if (order === "" && catagory === "" && filterAB !== "" && appName !== "") {
        if (
          filterAB === "ipad" ||
          filterAB === "iphone" ||
          filterAB === "web application" ||
          filterAB === "android"
        )
          return appList
            .filter((item) => item.devices.includes(filterAB))
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .map((item) => item);
        else if (filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3") {
          return appList
          .filter((item, i) =>  item.ageRange.includes(filterAB))
               .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .map((item) => item);
        }
      } 
       // esta condición es solo para filtrar por y campo manual de entrada (búsqueda por palabras)
      else if (catagory !== "" && order !== "" && filterAB === "" && appName === "") {
        if (order === "De menor a mayor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        } else if (order === "De mayor a menor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        }
      } 
        // esta condición es solo para Categorías, Ordenar por y campo manual de entrada (búsqueda por palabras)
      else if (catagory !== "" && order !== "" && filterAB === "" && appName !== "") {
        if (order === "De menor a mayor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        } else if (order === "De mayor a menor") {
          return orderList
            .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
               .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        }
      } 
             // esta condición es solo para Categorías, Ordenar por, Filtrar por y campo manual de entrada (búsqueda por palabras)
      else if (catagory !== "" && order === "" && filterAB !== "" &&  appName === "" ) {
        if (
          filterAB === "ipad" ||
          filterAB === "iphone" ||
          filterAB === "web application" ||
          filterAB === "android"
        )
          return appList
            .filter((item) => item.devices.includes(filterAB))
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        else if (filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3" ) {
          return appList
            .filter((item, i) =>  item.ageRange.includes(filterAB))
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        } 
    
          return appList
            .filter((item) => item.filter1.includes(catagory))
            .filter((item, i) => {
              return (
                parseFloat(item.ageRange[0]) >= 0 &&
                parseFloat(item.ageRange[1]) <= 5
              );
            })
            .map((item) => item);
        }
        // esta condición es solo para Categorías, Ordenar por, Filtrar por y campo manual de entrada (búsqueda por palabras)
      else if (catagory !== "" && order === "" && filterAB !== "" &&  appName !== "" ) {
        if (
          filterAB === "ipad" ||
          filterAB === "iphone" ||
          filterAB === "web application" ||
          filterAB === "android"
        )
          return appList
            .filter((item) => item.devices.includes(filterAB))
            .filter((item) => item.filter1.includes(catagory))
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .map((item) => item);
        else if (filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3") {
          return appList
             .filter((item, i) =>  item.ageRange.includes(filterAB))
            .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
        } 
      }
      else if (catagory !== "" && order !== "" && filterAB !== "" && appName==='') {
        if (order === "De menor a mayor") {
            if (
                filterAB === "ipad" ||
                filterAB === "iphone" ||
                filterAB === "web application" ||
                filterAB === "android"
              )
              {
                return orderList
                .sort(function (a, b) {
                  return parseInt(a.price) - parseInt(b.price);
                })
                .filter((item) => item.devices.includes(filterAB))
                .filter((item) => item.filter1.includes(catagory))
                .map((item) => item);
    
              }
         else if(filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3"){
            return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .filter((item, i) =>  item.ageRange.includes(filterAB))
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
          }
        }
          
        // Segunda condición
        else if (order === "De mayor a menor") {
            if (
                filterAB === "ipad" ||
                filterAB === "iphone" ||
                filterAB === "web application" ||
                filterAB === "android"
              )
              {
                return orderList
                .sort(function (a, b) {
                  return parseInt(b.price) - parseInt(a.price);
                })
                .filter((item) => item.devices.includes(filterAB))
                .filter((item) => item.filter1.includes(catagory))
                .map((item) => item);
    
              }
       else if(filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3"){
          return orderList
           .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
            .filter((item, i) =>  item.ageRange.includes(filterAB))
          .filter((item) => item.filter1.includes(catagory))
          .map((item) => item);
        }
        }
    }
      else if (catagory !== "" && order !== "" && filterAB !== "" && appName!=='') {
        if (order === "De menor a mayor") {
            if (
                filterAB === "ipad" ||
                filterAB === "iphone" ||
                filterAB === "web application" ||
                filterAB === "android"
              )
              {
                return orderList
                .sort(function (a, b) {
                  return parseInt(a.price) - parseInt(b.price);
                })
                   .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
                .filter((item) => item.devices.includes(filterAB))
                .filter((item) => item.filter1.includes(catagory))
                .map((item) => item);
    
              }
         else if(filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3"){
            return orderList
            .sort(function (a, b) {
              return parseInt(a.price) - parseInt(b.price);
            })
            .filter((item, i) =>  item.ageRange.includes(filterAB))
               .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
            .filter((item) => item.filter1.includes(catagory))
            .map((item) => item);
          }
        }
          
        // Segunda condición
        else if (order === "De mayor a menor") {
            if (
                filterAB === "ipad" ||
                filterAB === "iphone" ||
                filterAB === "web application" ||
                filterAB === "android"
              )
              {
                return orderList
                .sort(function (a, b) {
                  return parseInt(b.price) - parseInt(a.price);
                })
                .filter((item) => item.devices.includes(filterAB))
                .filter((item) => item.filter1.includes(catagory))
                .filter((item) =>
                item.description.toLowerCase().includes(appName.toLowerCase())
              )
                .map((item) => item);
    
              }
       else if(filterAB === "Senior" || filterAB === "Adulto" ||filterAB === "Secundaria" ||filterAB === "Primaria" ||filterAB === "Preescolar" ||filterAB === "Nacimiento a 3 a 3"){
          return orderList
           .sort(function (a, b) {
              return parseInt(b.price) - parseInt(a.price);
            })
            .filter((item, i) =>  item.ageRange.includes(filterAB))
          .filter((item) => item.filter1.includes(catagory))
             .filter((item) =>
            item.description.toLowerCase().includes(appName.toLowerCase())
          )
          .map((item) => item);
        }
        }
    }
        else {
          return appList.map((item) => item);
      }
    }
      
    setFilterData(filterList);
  }, [catagory, order, filterAB, appName]);
  return (
    <div className="full__list Contrast1">
      <div className="container-fluid Contrast1">
        <div className="row image__contain__relative  Contrast1">
          <div className="col-lg-5 Contrast1  ps-lg-5 full__list__left d-flex align-items-lg-start align-items-center justify-content-center flex-column">
            <Fade bottom>
              <h1 className="text-center text-lg-start full__list__col">
                ENCUENTRA <br />
                TODAS LAS APPs DE
                <br /> NUESTRA LISTA
              </h1>
              <button>
                <a href="#applist">Comienza tu búsqueda</a>
              </button>
            </Fade>
          </div>
          <div className="col-lg-7  Contrast1  d-none d-lg-block d-md-none ">
            <div className="row">
              <div className="col-lg-8 col-md-8 p-0 ">
                <div className="full__list__family  Contrast1">
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_1.png"
                      alt="Full_List_1"
                    />
                  </Fade>
                  <h3>Familias</h3>
                </div>
                <div className="full__list__Education  Contrast1">
                  <h3>Educadores</h3>
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_3.png"
                      alt="Full_List_1"
                    />
                  </Fade>
                </div>
              </div>
              <div className="col-lg-4  Contrast1  p-0">
                <div className="full__list__col1 Contrast1">
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_2.png"
                      alt="full list "
                    />
                  </Fade>
                </div>
                <div className="full__list__col2  Contrast1">
                  <h6>Cuidadores</h6>
                </div>
                <div className="full__list__col3  Contrast1">
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_4.png"
                      alt="full list "
                    />
                  </Fade>
                </div>
                <div className="full__list__col4"></div>
              </div>
            </div>
          </div>
          <div className="col-12  Contrast1 col-md-12  d-lg-none d-md-block d-block">
            <div className="row  Contrast1">
              <div className="col-lg-8 p-0  Contrast1 ">
                {/* <div className="full__list__family">
                  <img src="Images/Full_List_1.png" alt="Full_List_1" />
                </div> */}
                <div className="full__list__Education  Contrast1">
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_3.png"
                      alt="Full_List_1"
                    />
                  </Fade>
                </div>
              </div>
              <div className="col-lg-4 p-0  Contrast1">
                <div className="full__list__col1">
                  <Fade bottom>
                    <img
                      src="/Images/Full_List_2.png"
                      alt="full list "
                    />
                  </Fade>
                </div>
                <div className="full__list__col2">
                  <Fade bottom>
                    <img src="/Images/full_list3.png" alt="full list " />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  Contrast1" id="applist">
          <section className="full__list__search  Contrast1">
            <div className="row pb-lg-5  Contrast1">
              <div className="offset-lg-1  Contrast1 col-lg-6 full__list__search__content">
                <div className="link  Contrast1 d-flex justify-content-center">
                  <img src="/Icons/linkAppList.png" width="40" alt="" />
                </div>
                <h3>
                  LINKS HACIA LA <br />
                  INTEGRACIÓN E<br /> INCLUSIÓN
                </h3>
              </div>
            </div>
            <div className="row  Contrast1 d-flex justify-content-center">
              <div className="col-lg-6  Contrast1 ps-4 text-start text-lg-center fullList__mid">
                <p>
                  Encuentra APPs útiles, revisadas y clasificadas mediante
                  filtros unificados de selección
                </p>
              </div>
            </div>
            <div className="row  Contrast1 pb-5 d-flex justify-content-center">
              <div className="col-lg-5  Contrast1">
                <div className="input-group  Contrast1 mb-1 px-2 ps-lg-0">
                  <span className="input-group-text">
                    <AiOutlineSearch />
                  </span>
                  <input
                    value={appName}
                    onChange={(e) => setAppname(e.target.value)}
                    type="text"
                    className="form-control no-border"
                    aria-label="Amount (to the nearest dollar)"
                  />
                  <span className="input-group-text">
                    <AiOutlineClose onClick={() => setAppname("")} />
                  </span>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center  Contrast1">
              <div className="col-lg-10 Contrast1">
                <div className="row d-flex Contrast1 align-items-lg-center justify-content-between px-2">
                  <div className="col-lg-8  Contrast1 col-6">
                    <div className="row  Contrast1">
                      <div className="col-lg-3  Contrast1  col-12 todos__button me-4 me-lg-0">
                        <button onClick={() => HandleReset()}>Todas</button>
                      </div>
                      <div className="col-lg-4  py-2 py-lg-0 col-12 ">
                        <select
                          className="form-select form-select-full"
                          aria-label="Default select example"
                          onChange={(e) => setOrder(e.target.value)}
                        >
                          <option value="" >
                            Ordenar por
                          </option>
                          <optgroup label="Precio">
                            <option value="De menor a mayor">
                              De menor a mayor
                            </option>
                            <option value="De mayor a menor">
                              De mayor a menor
                            </option>
                          </optgroup>
                        </select>
                      </div>
                      <div className="col-lg-4  col-12  ">
                        <select
                          className="form-select form-select-full"
                          aria-label="Default select example"
                          onChange={(e) => setCatagory(e.target.value)}
                        >
                          <option value="" >
                            Categorías
                          </option>
                          <optgroup label="Aprendizaje">
                            <option value="Matemáticas"> Matemáticas </option>
                            <option value="Escritura"> Escritura </option>
                            <option value="Lectura"> Lectura </option>
                            <option value="Ciencias Sociales ">
                              {" "}
                              Ciencias Sociales{" "}
                            </option>
                            <option value="Ciencias"> Ciencias </option>
                            <option value="Habilidades sociales">
                              {" "}
                              Habilidades sociales{" "}
                            </option>
                            <option value="Emociones y estado de ánimo">
                              {" "}
                              Emociones y estado de ánimo{" "}
                            </option>
                            <option value="">
                              {" "}
                              Herramientas y plataformas de aprendizaje{" "}
                            </option>
                          </optgroup>
                          <optgroup label="Vida Independiente">
                            <option value="Vida independiente general">
                              {" "}
                              Vida independiente general
                            </option>
                            <option value="Finanzas"> Finanzas</option>
                          </optgroup>
                          <optgroup label="Ocio y entretenimiento">
                            <option value="Ocio y entretenimiento en general">
                              {" "}
                              Ocio y entretenimiento en general
                            </option>
                            <option value="Entretenimiento">
                              {" "}
                              Entretenimiento
                            </option>
                            <option value="Juegos"> Juegos </option>
                            <option value="Redes sociales y conexiones">
                              {" "}
                              Redes sociales y conexiones
                            </option>
                          </optgroup>
                          <optgroup label="Herramientas para">
                            <option>
                              {" "}
                              Accesibilidad alternativa y motricidad fina
                            </option>
                            <option value="Apoyo cognitivo">
                              {" "}
                              Apoyo cognitivo
                            </option>
                            <option value="Habilidades Sociales">
                              {" "}
                              Habilidades Sociales
                            </option>
                            <option value="Hablar"> Hablar</option>
                            <option value="Visión"> Visión</option>
                          </optgroup>
                          <optgroup label="Comunicación Aumentativa Alternativa (CAA)">
                            <option value="Seleccionar todas">
                              {" "}
                              Seleccionar todas
                            </option>
                          </optgroup>
                          <optgroup label="Salud y seguridad">
                            <option value="Salud mental"> Salud mental</option>
                            <option value="Salud y seguridad generales">
                              {" "}
                              Salud y seguridad generales
                            </option>
                            <option value="Gestión de medicación">
                              {" "}
                              Gestión de medicamentos
                            </option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-6  Contrast1 col-lg-3">
                    <div className="row  Contrast1">
                      <div className="col-lg-12  Contrast1 d-flex justify-content-end  col-12    me-1">
                        <select
                          className="form-select form-select-full "
                          aria-label="Default select example"
                          onChange={(e) => setFilter(e.target.value)}
                        >
                          <option value="" >
                            Filtrar por
                          </option>
                          <optgroup label="plataforma">
                            <option value="ipad">iPad</option>
                            <option value="iphone">iPhone</option>
                            <option value="web application">
                              Aplicación Web
                            </option>
                            <option value="android">Android</option>
                          </optgroup>
                          <optgroup label="años">
                            <option value="Senior">Senior</option>
                            <option value="Adulto">Adulto</option>
                            <option value="Secundaria">Escuela secundaria</option>
                            <option value="Primaria">Primaria</option>
                            <option value="Preescolar">Preescolar</option>
                            <option value="Nacimiento a 3">Nacimiento a 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  Contrast1 d-flex justify-content-center pt-4 ">
              <div className="col-lg-10 py-lg-4  Contrast1">
                <div className="row d-flex  Contrast1  flex-row">
                  <div className="col-lg-12  Contrast1 card-group  d-flex flex-wrap justify-content-center flex-lg-wrap justify-content-lg-start">
                    {filterData.map((item, i) => (
                      <Link
                        params={{ id: item._id }}
                        to={{ pathname: `/appinfo/${item._id}` }}
                      >
                        <AppCard key={i} data={item} />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row  Contrast1 d-flex justify-content-center ">
              <div className="col-lg-10  Contrast1 pt-lg-4">
                <div className="row d-flex   Contrast1  flex-row">
                  <div className=" px-3  Contrast1 col-lg-12 p-lg-4 p-0 section__button">
                    <p>
                      No has encontrado la aplicación que necesitas. Ponte en
                      contacto con nosotros y comentanos que aplicación
                      necesitas. Entre toda la comunidad tal vez podamos
                      encontrarla y si no existe, quizás descubramos personas
                      interesadas en desarrollarla.
                    </p>

                    <button className="mt-5">
                      <Link to="/contacto"> Escríbenos</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default FullList;

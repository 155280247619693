import React from "react";
import "./AppCard.css";
// importar { useAlert } from "react-alert";
import { AiFillAndroid, AiFillApple,AiFillStar } from "react-icons/ai";
function AppCard(props) {
  return (
    <div className="app__card m-2 Contrast1 bordr">
      <div
        className="card d-flex justify-content-center  Contrast1"
        style={{ width: "8.9rem" }}
      >
        <img
          className="card-img-top app__card__image"
          src={props.data.icon}
          alt="..."
        />
        <div className="card-body p-1 d-flex align-items-between flex-column Contrast1">
          <h5 className="card-title app__card__title Contrast1">
           {props.data.title}
          </h5>
          <p className="card-text app__card__company py-2">{props.data.company}</p>
            </div>
            <div className="container-fluid card-footer Contrast1">
            <div className="row pb-3 pt-2 d-flex justify-content-center flex-row" >
              <div className="col-6 p-0 d-flex align-items-center ">
                {props.data.ratingplay!==''&&<AiFillAndroid className='app__card__icons me-1' /> }
                <p className='app__card__rating p-0 m-0'>{props.data.ratingplay!=='' && props.data.ratingplay}</p>
                {props.data.ratingplay!==''&&<AiFillStar className='app__card__icons__star pt-1 me-1' />}
                
              </div>
              <div className="col-6 p-0  d-flex align-items-center justify-content-end">
                {props.data.ratingapple!=='' && <AiFillApple  className='app__card__icons' /> }
              
                <p className='app__card__rating p-0 m-0'>{props.data.ratingapple!=='' && props.data.ratingapple}</p>
                {props.data.ratingapple!=='' &&  <AiFillStar className='app__card__icons__star pt-1 me-1' /> }
                 
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppCard;

// Esta página se utiliza react-icons y react router domw
import React  ,{useEffect}from "react";
import "./Aboutus.css";
import { AiOutlineStar } from "react-icons/ai";
import {Link} from 'react-router-dom'
function AboutUs() {
  // esta function para desplazar la página hacía la parte superior.
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="aboutus Contrast1">
      <main>
        <section className="px-lg-5 px-2 Contrast1 aboutus__top ">
          <div className="container-fluid pt-5 image_relative Contrast1">
            <div className="row Contrast1">
              <div className="col-lg-6 aboutus__content Contrast1">
                <h3>
                  CREEMOS EN CÓMO LA TECNOLOGÍA PUEDE <br/> MEJORAR LA VIDA DE LAS
                  PERSONAS
                </h3>
                <p className="py-3">
                  tendiendo puentes hacia una mayor integración, autonomía y
                  control sobre sus vidas.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="image_container">
                  <img
                    src="/Images/Circle1.png"
                    className="circle1"
                    alt="image1"
                  />
                  <img
                    src="/Images/Circle2.png"
                    className="circle2"
                    alt="image2"
                  />
                  <img
                    src="/Images/Circle3.png"
                    className="circle3"
                    alt="image3"
                  />
                  <img
                    src="/Images/Circle4.png"
                    className="circle4"
                    alt="image4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Contrast1 aboutus__section2  ps-lg-5 pt-5 pt-lg-0">
          <div className="container-fluid ps-lg-4 Contrast1">
            <div className="row d-flex justify-content-between Contrast1">
              <div className="col-lg-6 px-3 d-flex align-items-center Contrast1 ">
                <div className="aboutus__section2_content Contrast1 d-flex justify-content-center flex-column">
                  <h1 className="quatation">“</h1>
                  <p>
                    ... la dificultad de atención, la hiperactividad, la
                    inflexibilidad, su comportamiento impulsivo, los problemas en el
                    aula... nos frustraron durante años hasta obtener un
                    diagnóstico. Es a partir de esa frustración y de la
                    motivación por querer encontrar más recursos que mejorasen
                    significativamente su vida, que nació El Arca, una web donde
                    compartimos información acerca de cómo determinadas
                    aplicaciones móviles para tablets o smartphones, pueden
                    ayudar a las personas con necesidades especiales.
                  </p>
                  <h1 className="text-end invert ">“</h1>
                  <br />
                  <h4 className="custom__padding ps-5 pb-5 pb-lg-0">
                    MIGUEL GONZÁLEZ
                    <br /> PADRE DE UN NIÑO NEURO-ATÍPICO Y FUNDADOR DE ’EL
                    ARCA’
                  </h4>
                </div>
              </div>
              <div className="col-lg-6  px-0 d-flex justify-content-end ">
                <img src="/Images/Family_Picture.png" className='aboutus__section2_content__img family__1' alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          className="section__3 Contrast1"
          style={{
            width: "100%",
            backgroundSize: "cover",
            backgroundImage: "url(/Images/Sobre_nosotros_Background.png)",
          }}
        >
          <div className="container Contrast1">
            <div className="row  p-0 text-center Contrast1 py-5 px-2 section__3__content">
              <h2 className="py-5">NUESTRO OBJETIVO</h2>
              <p className="pb-5">
                Que las personas con necesidades especiales de cualquier edad
                puedan beneficiarse de la información publicada en esta web
                acerca de cómo la tecnología y determinadas aplicaciones
                pueden mejorar sus vidas.
                <br />
                <br />
                Ofreciendo una base de datos de aplicaciones en la que
                podrán realizar búsquedas detalladas, con reseñas específicas
                para mejorar su capacidad de dominar una determinada habilidad o
                ser más autónomos e independientes.
              </p>
            </div>
          </div>
        </section>
        <section className="section__4 Contrast1">
          <div className="container-fluid ps-lg-5 ">
            <div className="row">
              <div className="col-lg-5 px-4 aboutus__contents Contrast1 ">
                <div className="aboutus__icon pt-5 pb-4 d-flex justify-content-start">
                  <AiOutlineStar className="bsarrow " />
                </div>
                <h4>
                  POR DÓNDE
                  <br />
                  EMPEZAR
                </h4>
                <p className="py-3 d-flex justify-content-start">
                  Los comienzos siempre son difíciles. Encontrar aplicaciones
                  apropiadas para sus necesidades puede ser una tarea
                  complicada, larga y confusa. Por eso El Arca le brinda esta
                  sencilla y adaptada herramienta de búsqueda.
                </p>
                <button className="my-5"><Link to='/lista-completa'> Listado de Aplicaciones</Link></button>
              </div>
              <div className="col-lg-7 d-none d-md-block px-0 app_img">
                <img
                  src="/Images/Sobre_nosotros_Ilustration.png"
                  className='aboutus__section2_content__img'
                  alt="ime"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default AboutUs;

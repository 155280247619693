// Página de inicio
// Utilizando iconos de react 
// Formulario de enlace react router dom 
// Carousel es usado desde react bootstrap y Fade desde react-reavel
import React, { useEffect } from "react";
import "./Home.css";
import { CgArrowUpO } from "react-icons/cg";
import { AiOutlineStar } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

function Home() {
  // esta función se utiliza para desplazar la página a la parte superior.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 
  return (
    <div className="home Contrast1">
      <section className="home__header Contrast1 ps-md-5">
        <div className="container-fluid home__height Contrast1 d-flex align-items-center">
          <div className="row home__header__row Contrast1 ">
            <div className="col-md-5 Contrast1 col-lg-5 d-flex align-items-center order-md-1 col-sm-12  order-1  order-lg-0">
              <div className="row Contrast1">
                {/* Section 1 de la Home page */}
                <div className="col-lg-8 Contrast1 home__content__bgcolor  col-md-12 col-sm-12 col-12 d-flex justify-content-center justify-content-md-start flex-column home__content pt-5 pe-3">
                  <Fade bottom>
                    <h4 className="text-uppercase text-md-start text-center mb-2 ">
                      UN PUENTE TECNOLÓGICO HACIA LA INTEGRACIÓN
                    </h4>
                    <p className="text-md-start text-center">
                      Encuentra APPs útiles, revisadas y clasificadas que
                      cambian la vida de las personas con necesidades
                      especiales.
                    </p>
                  </Fade>
                  <button className="mt-2 mb-4">
                    {" "}
                    <Link to="/lista-completa"> Listado de Aplicaciones</Link>
                  </button>
                </div>
              </div>
            </div>
            {/* Este código se utilizará para colocar a la derecha las imágenes (efecto)*/}
            <div className="col-md-12 col-lg-7 col-12 col-sm-12  order-0  order-md-0  order-lg-1 ">
              <div className="row   d-flex flex-row">
                <div className="col-9 col-sm-9 col-md-9   p-0">
                  <Carousel controls={false}  indicators={false}>
                    <Carousel.Item>
                      <img
                        src="/Images/Photo1_home.png"
                        className="d-block w-100"
                        alt="1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src="/Images/Photo2_home.png"
                        className="d-block w-100"
                        alt="2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src="/Images/Photo3_home.png"
                        className="d-block w-100"
                        alt="3"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src="/Images/Photo4_home.png"
                        className="d-block w-100"
                        alt="3"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src="/Images/Photo5_home.png"
                        className="d-block w-100"
                        alt="3"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div className="col-3 col-sm-3 col-md-3 home__orange home__padding Contrast1 px-0 d-flex justify-content-end align-items-center">
                  <img
                    className="home__image"
                    src="/Images/App_Home_Page_Example_(II).png"
                    alt="placeholder images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home__inclustion Contrast1  py-lg-5 px-2 px-lg-0 ">
        <div className="container-fluid Contrast1">
          <div className="row d-flex Contrast1 justify-content-center pt-lg-5 align-items-center home__inclustion__height">
            <div className="col-lg-10 Contrast1">
              <div className="row Contrast1">
                <div className="col-lg-5 pt-3 Contrast1 py-lg-5 home__inclustion__content">
                  {/* Fade importado de una biblioteca llamada React-Reavel */}
                  <Fade bottom>
                    <>
                      <div className="home__inclustion__icon px-5  py-3 d-flex justify-content-start justify-content-lg-end">
                       {/* este Componente es importado de react-icons*/}
                        <CgArrowUpO className="bsarrow icons" />
                      </div>
                      <h4>
                        INCLUSIÓN PARA <br /> TOD@S
                      </h4>
                      <p className="py-lg-5 pt-3 ps-lg-5 pe-lg-2 d-flex justify-content-end">
                        Con tu colaboración y la ayuda de la tecnología,
                        trabajamos para salvar la barrera de la inclusión,
                        construyendo un futuro mejor para tod@s.
                      </p>
                    </>
                  </Fade>
                </div>

                <div className="col-lg-6 ps-4 d-flex  justify-content-center justify-content-lg-end">
                  <Fade left>
                    <img
                      src="/Images/Illustration_1_-_Home_Page.png"
                      width="50%"
                      alt="img"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home__technology Contrast1 py-lg-5 px-2 px-lg-0">
        <div className="container-fluid Contrast1">
          <div className="row d-flex Contrast1 justify-content-center align-items-center home__technology__height">
            <div className="col-lg-10 Contrast1">
              <div className="row Contrast1">
                <div className="col-lg-7 order-1   pb-4 pb-lg-0 d-flex p-lg-5 justify-content-start  align-items-center">
                  <img
                    src="/Images/Illustration2-Home_Page.png"
                    width="100%"
                    height="80%"
                    alt="img"
                  />
                </div>
                <div className="col-lg-5 order-0 py-lg-5 pt-3 home__technology__content">
                  <Fade bottom>
                    <div className="home__technology__icon Contrast1 px-5 py-3  py-lg-3 d-flex justify-content-start justify-content-lg-end">
                      <CgArrowUpO className="bsarrow icons" />
                    </div>

                    <h4 className="text-uppercase">
                      TECNOLOGÍA <br /> PARA CONECTAR
                    </h4>
                    <p className="py-lg-5 pt-3 ps-lg-5 pe-lg-2 d-flex justify-content-end">
                      Hoy en día la tecnología está integrada en muchos aspectos
                      de la vida cotidiana. Disponer de aplicaciones accesibles
                      y útiles para las personas con necesidades especiales se
                      hace indispensable.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home__help Contrast1 py-lg-5 px-2 px-lg-0 ">
        <div className="container-fluid Contrast1">
          <div className="row d-flex Contrast1 justify-content-center align-items-center home__help__height">
            <div className="col-lg-10 Contrast1">
              <div className="row Contrast1">
                <div className="col-lg-5 pt-3 py-lg-5  home__help__content">
                  <Fade bottom>
                    <div className="home__help__icon px-5  py-3 d-flex justify-content-start justify-content-lg-end">
                      <AiOutlineStar className="bsarrow icons" />
                    </div>
                    <h4>
                      AYUDARLES <br /> ES AYUDARTE
                    </h4>
                    <p className="py-lg-5 pt-3 ps-lg-5 pe-lg-2 d-flex justify-content-end">
                      Instalar aplicaciones útiles ayuda a las personas con
                      necesidades especiales a tener una vida con la mayor
                      independencia posible, impulsa su integración, metas y
                      sueños, a la par que también mejoran la vida de sus
                      familias, educadores y/o cuidadores.
                    </p>
                  </Fade>
                </div>
                <div className="col-lg-7  ps-lg-4 d-flex  justify-content-lg-end align-items-center">
                  <Fade left>
                    <img
                      src="/Images/Illustration3-Home_Page.png"
                      width="100%"
                      height="80%"
                      alt="img"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
function Footer() {
  return (
    <div className="Footer py-5 px-lg-5 px-2 Contrast1">
      <div className="container-fluid  Contrast1">
        <div className="row Contrast1">
          <div className="col-md-12">
            <Link to="/" >
            <img
              src="/Images/El_Arca_logo_footer.png"
              width={150}
              alt="footer logo"
            /></Link>
          </div>
        </div>
        <div className="row py-5 d-flex flex-row Contrast1">
          <div className="col-md-3 col-6 footer__content Contrast1">
            <p className="p-0 head">La Web </p>
            <hr />
            <div className="py-2 Contrast1">
              <Link  to="/nosotros" className="footer__content__page">
                {" "}
                <p className="content1">Sobre nosotros</p>
              </Link>
              <Link to="/familias" className="footer__content__page">
                {" "}
                <p className="content1">Familias</p>
              </Link>
              <Link to="/educadores" className="footer__content__page">
                {" "}
                <p className="content1">Educadores</p>
              </Link>
              <Link to="" className="footer__content__page">
                {" "}
                <p className="content1">Involúcrate</p>
              </Link>
            </div>
          </div>
          <div className="col-md-6 p-0  col-6 footer__content  Contrast1">
            <p className="p-0 head">Contacto </p>
            <hr />
            <div className="py-2 footer__content__page">
              <p className="content1">elarcaapps@gmail.com</p>
            </div>
          </div>
          <div className="col-md-3 d-none d-md-block footer__content px-2">
            <p className="p-0 head">Social</p>
            <hr />
            <div className="py-2 footer__content__page">
              <a target="_blank" href="https://www.facebook.com/noexisteaun/">
                {" "}
                <FaFacebookF className="footer__icons" />
              </a>
              <a target="_blank" href="https://twitter.com/AppsArca">
                {" "}
                <FaTwitter className="footer__icons" />
              </a>
              <a target="_blank" href="https://www.instagram.com/elarcaapps/">
                {" "}
                <FaInstagram className="footer__icons" />
              </a>
            </div>
          </div>
        </div>
        <div className="row Contrast1">
          <div className="col-md-4 footer__content__end Contrast1">
            <p className="head">2022 El Arca. Todos los Derechos Reservados.</p>
          </div>
          <div className="col-md-3 footer__content__end Contrast1">
            <Link to="/privacidad" className="footer__content__end Contrast1">
              {" "}
              <p className="head">Política de Privacidad.</p>
            </Link>
          </div>
          <div className="col-md-2 footer__content__end Contrast1">
            <Link to="/legal" className="footer__content__end Contrast1">
              {" "}
              <p className="head">Aviso Legal.</p>
            </Link>
          </div>
          <div className="col-md-2 footer__content__end Contrast1">
            <Link to="/cookies" className="footer__content__end Contrast1">
              {" "}
              <p className="head">Política de Cookies.</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React,{useEffect} from "react";

function Legal() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="p-lg-5 px-3 py-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Aviso Legal</h2>
            <hr />
            <p className="content">
              Este Aviso Legal tiene el objetivo de informarte de tus derechos y
              obligaciones como usuario de este sitio web. Aquí encontrarás toda
              la información que necesitas sobre este sitio web, su actividad,
              los datos personales que recaba y su finalidad, así como las
              normas de uso que regulan el uso de esta web.
              <br />
              En el momento en que accedas a este sitio web elarcaapps.org,
              asumes la condición de usuario, por lo que el contenido de este
              Aviso Legal te afecta directamente. Por eso, es importante que lo
              leas para disipar cualquier duda que puedas tener y tener
              conocimiento de causa sobre las condiciones que estás aceptando.
              <br />
              Para empezar, debes saber que este sitio web cumple con las
              normativas vigentes en materia de protección de datos, con el
              objetivo de aportarte las garantías, la seguridad y la
              transparencia que, como usuario te corresponden, a la hora de
              utilizar esta web.
              <br />
              El RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del
              Consejo de 27 de abril de 2016 relativo a la protección de las
              personas físicas) que es la nueva normativa de la Unión Europea
              que unifica la regulación del tratamiento de los datos personales
              en los distintos países de la UE.
              <br />
              La LOPD (Ley Orgánica 15/1999, de 13 de diciembre, de Protección
              de Datos de Carácter Personal y Real Decreto 1720/2007, de 21 de
              diciembre, el Reglamento de desarrollo de la LOPD) que regula el
              tratamiento de los datos personales y las obligaciones que debemos
              asumir los responsables de una web o un blog a la hora de
              gestionar esta información.
              <br />
            </p>
            <p className="sub_heading">Datos de identificación</p>
            <p className="content">
              El responsable y titular de este sitio web es:
              <br />
              <br />
              Miguel González
              <br />
              Calle Velázquez, 1, 6ºD
              <br />
              <br />
              38007 Santa Cruz de Tenerife
              <br /> <br /> Teléfono: [A petición]
              <br />
              Correo electrónico: elarcaapps@gmail.com
              <br />
              <br />
              Los datos que nos facilites con tu consentimiento, y de acuerdo al
              uso establecido en nuestra Política de Privacidad, serán
              incorporados a un fichero automatizado debidamente inscrito en la
              Agencia Española de Protección de Datos, en el que el responsable
              de dicho fichero es: Miguel González Esto quiere decir que tus
              datos están seguros, de acuerdo a lo que establece la ley.
            </p>
            <p className="sub_heading">Normas de acceso al sitio web</p>
            <p className="content">
              Como usuario de nuestra web, también tienes una serie de
              obligaciones:
              <br />
              No podrás utilizar este sitio web para la realización de
              actividades contrarias a las leyes, a la moral, al orden público
              y, en general, a hacer un uso conforme a las condiciones
              establecidas en el presente Aviso Legal. .
              <br />
              No podrás realizar actividades publicitarias o de explotación
              comercial remitiendo mensajes que utilicen una identidad falsa.
              <br />
              Tú serás el único responsable de la veracidad y exactitud de los
              contenidos que introduzcas en esta web y de los datos personales
              que nos facilites con las finalidades previstas en nuestra
              Política de Privacidad.
              <br />
              También serás el único responsable de la realización de cualquier
              tipo de actuación ilícita, lesiva de derechos, nociva y/o
              perjudicial en los sitios de terceros a los que te podamos derivar
              desde este sitio web para el desarrollo de nuestra actividad.
              <br />
              Como responsable del sitio web, Miguel González podrá interrumpir
              el servicio de la página que esté siendo utilizado por el usuario
              y resolver de modo inmediato la relación si detecta un uso de la
              web o de cualquiera de los servicios que en la mismo se ofertan
              que pueda considerarse contrario a lo expresado en el presente
              Aviso Legal.
              <br />
            </p>
            <p className="sub_heading">Propiedad intelectual e industrial</p>
            <p className="content">
              La totalidad de este sitio web (texto, imágenes, marcas, gráficos,
              logotipos, botones, archivos de software, combinaciones de
              colores, así como la estructura, selección, ordenación y
              presentación de sus contenidos) se encuentra protegida por las
              leyes vigente sobre Propiedad Intelectual e Industrial, quedando
              prohibida su reproducción, distribución, comunicación pública y
              transformación, salvo para uso personal y privado.
              <br />
              Como titular de este sitio web, Miguel González no garantiza que
              los contenidos sean precisos o libres de error o que el libre uso
              de los mismos por parte de los usuarios no infrinja los derechos
              de terceras partes. El buen o mal uso de esta página y de sus
              contenidos es responsabilidad del usuario.
              <br />
              Asimismo, queda prohibida la reproducción, retransmisión, copia,
              cesión o redifusión, total o parcial, de la información contenida
              en la página, cualquiera que fuera su finalidad y el medio
              utilizado para ello, sin autorización previa de Miguel González.
              <br />
            </p>
            <p className="sub_heading">Enlaces o links</p>
            <p className="content">
              Este sitio web incluye enlaces o links a sitios de terceros. Las
              páginas pertenecientes a estos terceros no han sido revisadas ni
              son objeto de controles por nuestra parte, por lo que Miguel
              González no podrá ser considerado responsable de los contenidos de
              estos sitios web, ni de las medidas que se adopten relativas a su
              privacidad o al tratamiento de sus datos de carácter personal u
              otros que pudieran derivarse.
              <br />
              <br />
              Por todo ello, te recomendamos la lectura detenida de las
              condiciones de uso, política de privacidad, avisos legales y/o
              similares de estos sitios.
            </p>
            <p className="sub_heading">Limitación de responsabilidad</p>
            <p className="content">
              En ejercicio de su derecho como titular de esta web, te informamos
              de que Miguel González no se hace responsable en ningún caso de lo
              siguiente:
              <br />
            </p>
            <ul>
              <li className="content">
                La calidad del servicio, la velocidad de acceso, el correcto
                funcionamiento ni la disponibilidad ni continuidad de
                funcionamiento de la página.
              </li>
              <li className="content">
                La existencia de virus, malware, programas maliciosos o dañinos
                en los contenidos.
              </li>
              <li className="content">
                El uso ilícito, negligente, fraudulento o contrario a este Aviso
                Legal.
              </li>
              <li className="content">
                La falta de licitud, calidad, fiabilidad, utilidad y
                disponibilidad de los servicios prestados por terceros y puestos
                a disposición de los usuarios en este sitio web.
              </li>
              <li className="content">
                De los daños que pudieran provenir del uso ilegal o indebido de
                esta web.
              </li>
            </ul>
            <p className="sub_heading">
              Política de privacidad y protección de datos
            </p>
            <p className="content">
              Este sitio web cumple con la normativa vigente en materia de
              protección de datos, lo que implica que, como usuario, deberás dar
              tu consentimiento expreso antes de facilitarnos datos personales a
              través de los diferentes formularios puestos a disposición en las
              secciones de nuestra página.
              <br />
              <br />
              Para ello, en aras de la transparencia y el ejercicio de tu
              derecho, nuestro deber es informarte sobre los datos personales
              que recabamos, almacenamos y tratamos y con qué finalidades,
              teniendo en cualquier momento la posibilidad de revocar libremente
              tu consentimiento.
              <br />
              <br />
              Toda esta información la podrás encontrar en nuestra POLÍTICA DE
              PRIVACIDAD.
            </p>
            <p className="sub_heading">Política de cookies</p>
            <p className="content">
              Tal y como te informamos nada más acceder a nuestra web, este
              sitio utiliza cookies propias y de terceros con el objetivo de
              proporcionarte la mejor experiencia de usuario y desarrollar
              nuestra actividad.
              <br />
              <br />
              En cualquier momento, tendrás la opción de configurar tu navegador
              para desestimar el uso de estas cookies que, en algún caso,
              afectarán a tu experiencia de usuario.
              <br />
              <br />
              Para acceder a la información completa sobre el uso de cookies en
              este sitio web, su finalidad y su desestimación, puedes consultar
              nuestra POLÍTICA DE COOKIES.
              <br />
            </p>
            <p className="sub_heading">
              Legislación aplicable y jurisdicción competente
            </p>
            <p className="content">
              El presente Aviso Legal se encuentra sometido a la legislación
              española vigente.
              <br />
              <br />
              En caso necesario, ante cualquier tipo de controversia de carácter
              legal, Miguel González y el usuario, con renuncia expresa a
              cualquier otro fuero, se someterán a los Juzgados y Tribunales del
              domicilio de Miguel González controversia que pudiera derivarse.
              <br />
              <br />
              En caso necesario, ante cualquier tipo de controversia de carácter
              legal, Miguel González y el usuario, con renuncia expresa a
              cualquier otro fuero, se someterán a los Juzgados y Tribunales del
              domicilio de Miguel González controversia que pudiera derivarse.
              <br />
              <br />
              En el caso de que el usuario tenga su domicilio fuera de España,
              Miguel González y el usuario se someterán, con renuncia expresa a
              cualquier otro fuero, a los juzgados y tribunales de SANTA CRUZ DE
              TENERIFE (España).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;

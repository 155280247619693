import React, { useEffect, useState } from "react";
import "./Header.css";
import { FaBars } from "react-icons/fa";
import { AiFillAndroid, AiFillApple, AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "../../app/baseUrl";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { AiFillHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { changepassword, logout } from "../../features/userSlice";
import UserProfile from "../UserProfile/UserProfile";
import { useAlert } from "react-alert";
import { deleteFavourite, showFavourite } from "../../features/favouriteSlice";
import { showRating } from "../../features/ratingSlice";
import { showList } from "../../features/appSlice";
function Header() {
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => {
    setModal1(!modal1);
  };
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => {
    setModal2(!modal2);
  };
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => {
    setModal3(!modal3);
  };
  const alert = useAlert();
  const nevigation = useNavigate();
  const userDataauth = JSON.parse(localStorage.getItem("auth"));
  const userData = JSON.parse(localStorage.getItem("Data"));
  const [isOpen, setIsOpen] = useState(false);
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const { favouriteList } = useSelector(showFavourite);
  const { ratingList } = useSelector(showRating);
  const { appList } = useSelector(showList);
  const toggle = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  let location = useLocation();
  const [bgColor, setBgColor] = useState({ backgroundColor: "" });
  const [headerLogo, setHeaderlogo] = useState("");
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      setBgColor({ backgroundColor: "#39756d", color: "#fff" });
      setHeaderlogo("/Images/El_Arca_Logo_White.png");
    } else if (location.pathname === "/" || location.pathname === "/") {
      setBgColor({ backgroundColor: "#39756d", color: "#fff" });
      setHeaderlogo("/Images/El_Arca_Logo_White.png");
    } else if (
      location.pathname === "/nosotros/" ||
      location.pathname === "/nosotros"
    ) {
      setBgColor({ backgroundColor: "#39756d", color: "#fff" });
      setHeaderlogo("/Images/El_Arca_Logo_White.png");
    } else if (
      location.pathname === "/familias/" ||
      location.pathname === "/familias"
    ) {
      setBgColor({ backgroundColor: "#ec6339", color: "#fff" });
      setHeaderlogo("/Images/El_Arca_Logo_White.png");
    } else if (
      location.pathname === "/educadores/" ||
      location.pathname === "/educadores"
    ) {
      setBgColor({ backgroundColor: "#ec6339", color: "#fff" });
      setHeaderlogo("/Images/El_Arca_Logo_White.png");
    } else if (
      location.pathname.slice(0,9) === "/appinfo/" ||
      location.pathname === "/appinfo/"
    ) {
      setBgColor({ backgroundColor: "#fff", color: "#000" });
      setHeaderlogo("/Images/El_Arca_logo_Black.png");
    } else if (
      location.pathname === "/contacto/" ||
      location.pathname === "/contacto"
    ) {
      setBgColor({ backgroundColor: "#23222C", color: "#DDD7EB" });
      setHeaderlogo("/Images/El_Arca_logo_footer.png");
    } else if (
      location.pathname === "/user-login/" ||
      location.pathname === "/user-login"
    ) {
      setBgColor({ backgroundColor: "#F5F5F5", color: "#000" });
      setHeaderlogo("/Images/El_Arca_logo_Black.png");
    } else if (
      location.pathname === "/lista-completa/" ||
      location.pathname === "/lista-completa"
    ) {
      setBgColor({ backgroundColor: "#fff", color: "#000" });
      setHeaderlogo("/Images/El_Arca_logo_Black.png");
    } else if (
      location.pathname === "/privacidad" ||
      location.pathname === "/legal" ||
      location.pathname === "/cookies"
    ) {
      setBgColor({ backgroundColor: "#fff", color: "#000" });
      setHeaderlogo("/Images/El_Arca_logo_Black.png");
    }
  }, [location]);
  const HandleLogout = () => {
    dispatch(logout()).then((res) => {
      alert.success("Sesión cerrada con éxito");
      nevigation("/");
    });
  };
  const HandleChange = (event) => {
    event.preventDefault();
    const value = {
      oldpassword: oldpassword,
      newpassword: newpassword,
    };
    const id = userData._id;
    const data = { id, value };
    dispatch(changepassword(data)).then((res) => {
      alert.success("Tu contraseña ha sido cambiada exitosamente");
     
    });
  };
  const [open, setOpen] = useState("");
  const toggle4 = (id) => {
    open === id ? setOpen() : setOpen(id);
  };

  const HandleDelete=(favid)=>{
   dispatch(deleteFavourite(favid))
   .then((res)=>  alert.success("Deleted"))
   .catch((res)=>  alert.error("Error "+res))
  }

  return (
    <div className="header Contrast1">
      <Modal isOpen={modal1} toggle={toggle1} className='Contrast1'>
        <ModalBody className='Contrast1'>
          <div>
            <label htmlFor="basic-url" className="form-label">
              Contraseña anterior <span className="important">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                value={oldpassword}
                onChange={(e) => setOldpassword(e.target.value)}
                type="text"
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
            <label htmlFor="basic-url" className="form-label">
              Nueva contraseña <span className="important">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
                type="password"
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='Contrast1'>
          <form onClick={HandleChange}>
            <Button className="submit__button m-0 mx-1" onClick={toggle1}>
              Cambia la contraseña
            </Button>{" "}
          </form>
          <Button className="submit__button m-0 mx-1" onClick={toggle1}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} className='Contrast1'>
        <ModalHeader toggle={toggle2} className="text-center Contrast1">
          Favoritas
        </ModalHeader>
        <ModalBody className='Contrast1'  >
          {userDataauth &&
            favouriteList
              .filter(item=>item.user !==null)
              .filter((item) => item.user._id === userData._id)
              .map((item) => (
                <Link   params={{ id: item.applist._id }}
                        to={{ pathname: `/appinfo/${item.applist._id}` }} >
                <div
                  className="card mb-3 Contrast1 border d-flex justify-content-center"
                  style={{ maxWidth: "540px;", height: "100px" }}
                >
                  <div className="row g-0">
                    <div className="col-md-2">
                      <img
                        src={item.applist.icon}
                        width="70"
                        className="img-fluid rounded-start"
                        alt="..."
                      />
                    </div>
                    <div className="m-0 col-md-8">
                      <div className="card-body py-1">
                        <div className="row">
                          <div className="col-11 m-0 p-0">
                            <h6 className="card-title">{item.applist.title}</h6>
                          </div>
                          <div className="col-1">
                          <AiFillHeart onClick={()=>HandleDelete(item._id)} className={`icon  FavouriteApp`} />
                          </div>
                          <div className="col-12">
                            <div className="row  pt-1 d-flex justify-content-start flex-row">
                              <div className="col-3 p-0 d-flex align-items-center ">
                                {item.applist.ratingplay !== "" && (
                                  <AiFillAndroid className="app__card__icons me-1" />
                                )}
                                <p className="app__card__rating p-0 m-0">
                                  {item.applist.ratingplay !== "" &&
                                    item.applist.ratingplay}
                                </p>
                                {item.applist.ratingplay !== "" && (
                                  <AiFillStar className="app__card__icons__star pt-1 me-1" />
                                )}
                              </div>
                              <div className="col-3 p-0  d-flex align-items-center justify-content-start">
                                {item.applist.ratingapple !== "" && (
                                  <AiFillApple className="app__card__icons" />
                                )}

                                <p className="app__card__rating p-0 m-0">
                                  {item.applist.ratingapple !== "" &&
                                    item.applist.ratingapple}
                                </p>
                                {item.applist.ratingapple !== "" && (
                                  <AiFillStar className="app__card__icons__star pt-1 me-1" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              ))}
        </ModalBody>
      </Modal>

      <Modal isOpen={modal3} toggle={toggle3} className='Contrast1'>
        <ModalBody className='Contrast1'>
          {userDataauth && ratingList.filter(item=>item.user !== null).filter(itemss=>itemss.user._id===userData._id).map((item,i)=>
        { return (<Accordion flush open={open} toggle={toggle4} className='Contrast1'>
            <AccordionItem className='Contrast1'>
              <AccordionHeader className='Contrast1' targetId={i}>{appList.filter(items=>items._id===item.app).map(item=>item.title)}</AccordionHeader>
              <AccordionBody className='Contrast1' accordionId={i}>
               {item.review}
              </AccordionBody>
            </AccordionItem>
          </Accordion>)}
          )}
        </ModalBody>
      </Modal>

      <Navbar
        expand={"lg"}
        container="fluid "
        className="py-4 px-lg-5 px-2 Contrast1"
        style={bgColor}
      >
        <NavbarBrand onClick={() => setIsOpen(false)}>
          {" "}
          <NavLink to="/">
            {" "}
            <img src={headerLogo} alt="logo" width="150" />
          </NavLink>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <FaBars className="fabars " style={bgColor} />
        </NavbarToggler>
        <Collapse className="Contrast1" isOpen={isOpen} navbar>
          <Nav className="ms-auto d-flex align-items-center " navbar>
            <NavItem>
              <NavLink onClick={toggle} className={"nav-link"} to={"/nosotros"}>
                {" "}
                <h6>Sobre nosotros</h6>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={toggle} className={"nav-link"} to="/familias">
                {" "}
                <h6>Familias</h6>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={toggle} className={"nav-link"} to="/educadores">
                {" "}
                <h6>Educadores</h6>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={toggle} className={"nav-link"} to="/contacto">
                {" "}
                <h6>Contacto</h6>
              </NavLink>
            </NavItem>
            <NavItem>
              {userDataauth ? (
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "unset", border: "unset" }}
                  >
                    <img
                      src={userData.profileImg}
                      width={50}
                      height={50}
                      className="roundImg"
                      alt={userData.username}
                    />
                  </button>
                  <ul className="dropdown-menu  dropdown-menu-end">
                    <li>
                      <label
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="dropdown-item"
                      >
                        Perfil
                      </label>
                    </li>
                    <li>
                      <label className="dropdown-item" href="/" onClick={toggle2}>
                        Favoritas
                      </label>
                    </li>
                    <li>
                      <label className="dropdown-item" onClick={toggle1}>
                        Cambia la contraseña
                      </label>
                    </li>
                    <li>
                      <label className="dropdown-item" onClick={toggle3}>
                        Mis reseñas
                      </label>
                    </li>
                    <li>
                      <label
                        onClick={() => HandleLogout()}
                        className="dropdown-item"
                      >
                        Cerrar Sesión
                      </label>
                    </li>
                  </ul>
                </div>
              ) : (
                <NavLink
                  onClick={toggle}
                  className={"nav-link"}
                  to="/user-login"
                >
                  {" "}
                  <h6 className="button">Involúcrate</h6>
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <div className="userLogin">
        <div
          className="modal fade"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg Contrast1">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body Contrast1">
                <UserProfile />
              </div>
            </div>
          </div>
        </div>

        {/* <nav
        className="navbar navbar-expand-lg py-4   px-lg-5 px-2"
        style={bgColor}
      >
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img src={headerLogo} alt="logo" width="150" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   
            <span className="navbar-toggler-icons">
                <FaBars className="fabars" style={bgColor} />
            </span>
          
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 pe-3 d-flex justify-content-start align-items-start">
              <li className="nav-item px-3 py-1">
                <NavLink className={"nav-link"} to={"/nosotros"}>
                  {" "}
                  <h6>Sobre nosotros</h6>
                </NavLink>
              </li>
              <li className="nav-item px-3 py-1">
                <NavLink className={"nav-link"} to="/familias">
                  {" "}
                  <h6>Familias</h6>
                </NavLink>
              </li>
              <li className="nav-item px-3 py-1">
                <NavLink className={"nav-link"} to="/educadores">
                  {" "}
                  <h6>Educadores</h6>
                </NavLink>
              </li>
              <li className="nav-item px-3 py-1">
                <NavLink className={"nav-link"} to="/contacto">
                  {" "}
                  <h6>Contacto</h6>
                </NavLink>
              </li>
              <li className="nav-item px-3 py-1">
                <NavLink className={"nav-link"} to="/user-login">
                  {" "}
                  <h6 className="button">Involúcrate</h6>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      </div>
    </div>
  );
}

export default Header;

import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./AppDescription.css";
function AppDescription({des,title}) {
  return (
    <div className="app-description Contrast1 p-5">
      <div className="container-fluid Contrast1 p-0">
        <div className="row Contrast1">
          <div className="col-md-12 Contrast1">
            <div className="row Contrast1">
              <div className="col-1">
                <button
                  type="button"
                  className="close_button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className="col-11 change_size Contrast1">
                <h5>{title}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12 pt-4 change_size Contrast1">
                <p className="app__orange">Descripción</p>
                <p>
                 {des}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDescription;

import React, { useState } from "react";
import UserProfile from "../../Components/UserProfile/UserProfile";
import "./UserLogin.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, reset } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
function UserLogin() {
  const nevigation = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [emailReset, setEmailreset] = useState(null);
  const [newpassword, setNewpassword] = useState(null);
  const [password, setPassword] = useState(null);
  const alert = useAlert();
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const HandleSubmit = (event) => {
    event.preventDefault();
    const value = {
      email: email,
      password: password,
    };
    dispatch(login(value))
      .then((result) => {
   
        if (!result.payload) {
          alert.error(" Contraseña incorrecta ");
        } else {
          alert.success(" Conectado ");
          nevigation("/");
        }
      })
      .catch((err) => {
    
        alert.error("Error " + err);
      });
  };
  const HandleReset = (event) => {
    event.preventDefault();
    const value = {
      email: emailReset,
      password: newpassword,
    };
   
    dispatch(reset(value))
      .then((res) => {
        alert.success("Restablecimiento de contraseña");
       
        setEmailreset();
        setNewpassword();
      })
      .catch((err) => alert.success("Error " + err));
  };

  return (
    <div className="userLogin">
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <UserProfile />
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} className='Contrast1'>
      <ModalHeader toggle={toggle} className='Contrast1'>Restablecer la contraseña</ModalHeader>
        <ModalBody className='Contrast1'>
          <div>
            <label htmlFor="basic-url" className="form-label">
              Correo electrónico <span className="important">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                value={emailReset}
                onChange={(e) => setEmailreset(e.target.value)}
                type="text"
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
            <label htmlFor="basic-url" className="form-label">
              Nueva contraseña <span className="important">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
                type="password"
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='Contrast1'>
          <form onSubmit={HandleReset}>
            <Button className='submit__button m-0 ' type="submit" onClick={toggle}>
              restablecer la contraseña
            </Button>{" "}
          </form>
          <Button className='submit__button m-0' onClick={toggle}>
            cerca
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid Contrast1">
        <div className="row Contrast1 userLogin__row d-flex justify-content-center  justify-content-lg-start">
          <div className="col-lg-6 Contrast1 col-12 pt-2  px-4 p-0 px-lg-5 d-flex justify-content-center flex-column userLogin__left  order-1 order-lg-0">
            <lable className="important">¿QUÉ ES EL ARCA?</lable>
            <h4 className="text-uppercase">
              UNA COMUNIDAD QUE UNE TECNOLOGÍA E INTEGRACIÓN
            </h4>
            <p className="pt-lg-4 pt-4 m-0">
              ¡Ayúdanos a crecer! El Arca busca familiares, educadores,
              cuidadores o personas con necesidades especiales interesadas en
              compartir su experiencia con el uso de determinadas aplicaciones
              móviles que mejoran sus vidas. Tan solo con un poco de su tiempo y
              talento podemos cambiar la vida de muchas personas.
            </p>
            <p className="special pt-2 Contrast1">
              <Link to="/nosotros"> Conoce más sobre El Arca </Link>
            </p>
          </div>
          <div
            className="col-lg-6 col-12 p-0 py-5 py-lg-5 userlogin__right order-0 order-lg-1"
            style={{
              backgroundImage: "url(/Images/Contact_Background.png)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="row userLogin__right Contrast1">
              <div className="col-lg-12 Contrast1 col-12 userLogin__right__FFF d-flex align-items-center flex-column">
                <h1 className="userLogin__right__heading">Acceder</h1>
                <div className="google__color">
                  <img
                    src="/Icons/Google.png"
                    alt="google"
                    width={100}
                  />
                </div>

                <label htmlFor="basic-url" className="form-label">
                  O registrate con tu email y contraseña
                </label>
                <form
                  onSubmit={HandleSubmit}
                  className="px-5 Contrast1 w-100 d-flex justify-content-center flex-column"
                >
                  <div>
                    <label htmlFor="basic-url" className="form-label">
                      Email <span className="important">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        className="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                    <label htmlFor="basic-url" className="form-label">
                      Contraseña <span className="important">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <label className="text-end" onClick={toggle}>
                    ¿Contraseña olvidada?
                  </label>
                  <button className="submit__button">Enviar</button>
                </form>
                <p
                  type="button"
                  className="m-0 userlogin__right__p"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  ¿No tienes una cuenta? Registrate{" "}
                </p>
                <p className="m-0 userlogin__right__p">
                  {" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
